import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import InvalidOrderScreen from "../ui-components/invalid-order";
// @ts-ignore
import { createCheckoutSession } from "../stripe/stripe-controller.tsx";
import {
  CreateCheckoutSessionParamForPfmOrder,
  CreateSessionResponse,
} from "../models/app-models";
// @ts-ignore
import ItemsOutOfStock from "../ui-components/ItemsOutStock.tsx";
// @ts-ignore
import CircularProgressWithLockIcon from "../ui-components/CircularProgressWithLockIcon.tsx";

// The payment link page where the payer reviews and pays for the order.
// E.g. http://pay.payforme.io/pfm/orderRef
// This page redirects to Stripe checkout.
export default function PaymentLinkPage() {
  var pathName = window.location.pathname;
  var orderRef = pathName.substring(pathName.lastIndexOf("/") + 1);

  const [data, setData] = useState<CreateSessionResponse>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    async function fetchData() {
      try {
        const param: CreateCheckoutSessionParamForPfmOrder = {
          order_ref: orderRef,
          type: "orderRef",
        };
        const res: CreateSessionResponse = await createCheckoutSession(param);

        if (res.sessionUrl) {
          window.location.assign(res.sessionUrl!);
          return;
        }

        setData(res);
      } catch (err: any) {
        setError(err.message);
      }
    }

    fetchData();
  }, []);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!data) {
    return <CircularProgressWithLockIcon />;
  }

  if (data.orderStatus !== "awaiting_payment") {
    return <InvalidOrderScreen />;
  }

  if (data.lineItemsOutOfStock) {
    return <ItemsOutOfStock data={data} />;
  }

  throw new Error("unknown screen state");
}
