import { useState, useEffect } from "react";
import * as React from "react";
import { Typography, Alert, AlertTitle, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
// @ts-ignore
import paymentCompanies from ".././payment-options-icons.png";
import Grid from "@mui/material/Grid";
import currency from "currency.js";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AppLogo from "../ui-components/app-logo";
import LoadingButton from "@mui/lab/LoadingButton";
import CreditCardTwoToneIcon from "@mui/icons-material/CreditCardTwoTone";
// @ts-ignore
import { logAppEvent } from "../firebase/firebase-initializer.ts";
// @ts-ignore
import { fetchBusinessInfo } from "../dashboard/dashboard-controller.ts";
// @ts-ignore
import { createCheckoutSession } from "../stripe/stripe-controller.tsx";
// @ts-ignore
import { fetchCheckoutLink } from "../orders/order-controller.tsx";
// @ts-ignore
import TermsAndConditionsFooter from "../ui-components/TermsAndConditionsFooter.tsx";
// @ts-ignore
import CircularProgressWithLockIcon from "../ui-components/CircularProgressWithLockIcon.tsx";
import {
  CheckoutLinkResponse,
  CreateCheckoutSessionParamForDirectPay,
  DirectPaymentUIModel,
} from "../models/app-models";
// @ts-ignore
import { AppConst } from "../common/app-constants.ts";

/* Link to the page:
  http://localhost:3000/biz/bizRef

  This file handles the business payment page. A simple page where a customer can 
  input an amount and pay. 
*/
export default function DirectPaymentPage() {
  var pathName = window.location.pathname;
  var merchantId = pathName.substring(pathName.lastIndexOf("/") + 1);

  const initalUIModel: DirectPaymentUIModel = {
    merchantExists: false,
    businessLogoUrl: "",
    businessName: "",
    isLoading: true,
  };
  const [pageError, setPageError] = useState(null);
  const [uiData, setUiData] = useState(initalUIModel);
  const [money, setMoney] = useState(0);
  const [description, setDescription] = useState("");
  const [inputError, setInputError] = useState(false);
  const [continuePaymentLoading, setContinuePaymentLoading] =
    React.useState(false);
  const [payWithPayForMeLoading, setPayWithPayForMeLoading] =
    React.useState(false);
  const buttonLoadingText = "Processing...";
  const buttonLoadingBackgroundColor = "darkgrey";

  function convertToUiModel(data: any): DirectPaymentUIModel {
    const model: DirectPaymentUIModel = {
      merchantExists: data.exists,
      businessLogoUrl: data.logoUrl,
      businessName: data.displayName,
      isLoading: false,
    };

    return model;
  }

  async function fetchData() {
    try {
      const data = await fetchBusinessInfo(merchantId);
      const uiModel = convertToUiModel(data);

      setUiData(uiModel);
    } catch (err) {
      setPageError(err.message);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (uiData.isLoading) {
    return <CircularProgressWithLockIcon />;
  }

  if (pageError) {
    return <Alert severity="error">{pageError}</Alert>;
  }

  if (uiData.merchantExists === false) {
    return <MerchantNotFoundScreen />;
  }

  return (
    /* This is the main div that holds the whole
    page together. It contains a CSS style for text
    alignment that makes any type of text that is put
    within the page to be aligned at the cetner of the
    page.*/
    <Container maxWidth="xs" style={{ paddingTop: "30px" }}>
      <Stack>
        <Grid container justifyContent="center" alignItems="center">
          {uiData.businessLogoUrl && (
            <img
              style={{ width: "90px", height: "90px" }}
              src={`${uiData.businessLogoUrl}`}
            />
          )}
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="h5">{uiData.businessName}</Typography>
        </Grid>
        <Typography style={{ paddingBottom: "5px", paddingTop: "30px" }}>
          Enter the amount you would like to pay*:
        </Typography>
        {/* This div contains the dollar input textfield that is used to input
      the dollar amount of a payment. The user is able to type in any valid 
      dollar amount along with taking in numbers from 1 to 9 the textfield
      takes one decimal point to allow the user to input cents.  */}
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            className="payment"
            sx={{ border: "black" }}
            onKeyDown={checkInput}
            id="payment"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            endAdornment={<InputAdornment position="end">USD</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            color="secondary"
            onChange={setCurrency}
          ></OutlinedInput>
        </FormControl>

        {inputError && (
          <Alert severity="error">
            <AlertTitle>Please input a valid amount above</AlertTitle>
          </Alert>
        )}

        <Box sx={{ height: "20px" }} />

        <Typography style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          Description (Optional):
        </Typography>

        <div>
          <TextField
            id="description"
            label="Add your description here"
            multiline
            fullWidth
            color="secondary"
            rows={3.75}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
          />
        </div>

        <Box sx={{ height: "20px" }} />
        <LoadingButton
          variant="contained"
          fullWidth
          style={{
            color: "white",
            background: `${
              continuePaymentLoading ? buttonLoadingBackgroundColor : "#3f50b5"
            }`,
          }}
          onClick={() => {
            onClickContinueWithPayment();
          }}
          startIcon={<CreditCardTwoToneIcon />}
          loading={continuePaymentLoading}
          loadingPosition="end"
          endIcon={<></>}
        >
          {continuePaymentLoading ? buttonLoadingText : "Continue with Payment"}
        </LoadingButton>

        <div style={{ paddingTop: "10px", marginRight: "160px" }}>
          <img src={paymentCompanies} style={{ width: "200px" }} />
        </div>

        <Divider
          style={{
            margin: "auto",
            paddingTop: "20px",
            paddingBottom: "20px",
            width: "350px",
          }}
        >
          or
        </Divider>

        <div
          style={{
            paddingTop: "7px",
            height: "40px",
            paddingBottom: "2px",
          }}
        >
          <LoadingButton
            variant="contained"
            fullWidth
            style={{
              color: "white",
              background: `${
                payWithPayForMeLoading
                  ? buttonLoadingBackgroundColor
                  : "primary"
              }`,
            }}
            onClick={() => {
              onClickPayWithPayForMe();
            }}
            loading={payWithPayForMeLoading}
            loadingPosition="end"
            endIcon={<></>}
          >
            {payWithPayForMeLoading
              ? buttonLoadingText
              : `Pay with ${AppConst.APP_NAME}`}
          </LoadingButton>
        </div>

        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="body2">
            Let someone else pay using {AppConst.APP_NAME}
          </Typography>
          <InfoButton />
        </Grid>
        <Box sx={{ height: "30px" }} />
        <TermsAndConditionsFooter />
      </Stack>
    </Container>
  );

  function setCurrency(input) {
    const trimmedInput = input.target.value.trim();

    //Rounded up 2 decimal places.
    const rounded = currency(trimmedInput, { precision: 2 });
    const moneyIntValue = rounded.intValue;
    console.log("Rounded: " + rounded + " Int: " + moneyIntValue);

    setMoney(moneyIntValue);

    clearInputErrorIfNeeded();
  }

  /**
   * Clears input errors.
   * Used after the user has entered a valid input
   */
  function clearInputErrorIfNeeded() {
    if (inputError) {
      setInputError(false);
    }
  }

  /* 
  Validates input before submitting the user payment.
  Returns true if sucessful and false if there are any errors
  with the input.
  */
  function previewSubmission() {
    if (!isValidInput(money)) {
      setInputError(true);
      return false;
    }

    //The value of money has to be >= $0.50
    if (money < 50) {
      setInputError(true);
      return false;
    }

    setInputError(false); //No input error.
    return true; //Successful submission.
  }

  /**
   * Handle clicking on Continue with Payment
   */
  async function onClickContinueWithPayment() {
    if (!previewSubmission()) {
      return;
    }

    setContinuePaymentLoading(true);
    try {
      const param: CreateCheckoutSessionParamForDirectPay = {
        merchantId: merchantId,
        type: "directpay",
        totalAmount: money,
        description: description,
        currency: "USD",
        successUrl: `${window.location.origin}`, // server will append urlParams
        cancelUrl: `${window.location.href}`,
      };
      const data = await createCheckoutSession(param);

      window.location.assign(data.sessionUrl);
    } catch (err) {
      console.log(err.message);
    }
  }

  /**
   * Handle clicking on Pay with PFM button
   */
  async function onClickPayWithPayForMe() {
    if (!previewSubmission()) {
      return;
    }

    setPayWithPayForMeLoading(true);
    try {
      const res: CheckoutLinkResponse = await fetchCheckoutLink({
        merchantId: merchantId,
        description: description,
        amount: money,
        currency: "USD",
        source: "directpay",
      });

      window.location.assign(res.data.checkoutLink);
    } catch (err) {
      setPageError(err.message);
    }
  }
}

/* This function checks if the current input is within the range of 
  0 to 9 or is a backspace. If the key is equal to those values
  then this function will display those numbers on the screen.  
  works only with currencies like USD.*/
function checkInput(event) {
  const isDigit = event.key >= "0" && event.key <= "9";
  const isDecimalPoint = event.key === ".";

  if (event.key == "Backspace") {
    return;
  }

  if (!(isDigit || isDecimalPoint)) {
    console.log("The event.key is neither a digit or a decimal point.");
    event.preventDefault();
    return;
  }

  if (!isValidInput(event.target.value + event.key)) {
    event.preventDefault();
  }
}

function isValidInput(input) {
  if (isNaN(input)) {
    console.log("Input: " + input + " is not a number:");
    return false;
  }

  return true;
}

function InfoButton() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    logAppEvent("select_content", {
      content_type: "button",
      item_id: "pfm_info_icon_button",
      item_name: "pfm_info_icon_button",
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen} aria-label="Learn More">
        <InfoIcon />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          <Typography variant="body1" fontWeight="bold">
            About {AppConst.APP_NAME}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {AppConst.APP_NAME} is a payment platform that allows users to send
            a payment link to any individual(s) they want to pay for them.{" "}
            <a
              href="https://payforme.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function MerchantNotFoundScreen() {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="sm">
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <AppLogo />
            <Typography gutterBottom variant="body1">
              We are sorry we couldn't find a business with this link.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
