const LOGO_URL = "https://payforme-io.github.io/assets/logo.png";

export default function AppLogo() {
  return <AppLogoImage />;
}

const AppLogoImage = () => {
  return (
    <p>
      <img src={LOGO_URL} width="190px" alt="payforme logo" />
    </p>
  );
};
