// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { EmailAuthProvider, getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";
import { connectFunctionsEmulator } from "firebase/functions";
// @ts-ignore
import { AppConfig } from "../common/app-config.ts";
// @ts-ignore
import { AppConst } from "../common/app-constants.ts";

// Initialize Firebase
const app = firebase.initializeApp(AppConfig.getFirebaseConfig());
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);

// Whether we're running in Sandbox Mode.
// // Deprecated. TODO: Remove.
export const IS_SANDBOX_MODE = false;

// Enable only when using firebase functions emulator to test locally
const ENABLE_FUNCTIONS_EMULATOR = false;

connectFunctionsEmulatorIfEnabled();

export function getFbFirestore() {
  return db;
}

export function getFbFunctions() {
  return functions;
}

export function getFbAuth() {
  return auth;
}

/**
 * Returns Firebase ui config option
 * @param destinationUrl redirect to this url after signin succeeds
 * @returns {any} Firebase UI Config
 */
export function getFbUiConfig(destinationUrl: string): any {
  // Configure firebase UI
  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: destinationUrl,
    signInOptions: [
      // {
      //   provider: GoogleAuthProvider.PROVIDER_ID,
      //   fullLabel: "Continue with Google",
      // },
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        fullLabel: "Continue with Email",
      },
    ],
    tosUrl: "https://www.payforme.io/terms-and-conditions",
    privacyPolicyUrl: "https://www.payforme.io/privacy-policy",
  };

  return uiConfig;
}

/**
 * Logs event using Firebase Analytics
 * @param {string} eventName
 * @param {object} params containg key-value pairs
 */
export function logAppEvent(eventName, params) {
  logEvent(analytics, eventName, params);
}

export function getFunctionsBaseUrl() {
  const projectId = app.options.projectId;
  const region = "us-central1";
  return ENABLE_FUNCTIONS_EMULATOR
    ? `http://localhost:5001/${projectId}/${region}`
    : `https://${region}-${projectId}.cloudfunctions.net`;
}

function connectFunctionsEmulatorIfEnabled() {
  if (ENABLE_FUNCTIONS_EMULATOR) {
    connectFunctionsEmulator(functions, "localhost", 5001);
  }
}

export function getHttpApiV1RequestUrl() {
  return `${getFunctionsBaseUrl()}/${AppConst.HTTP_API_V1_ENDPOINT}`;
}
