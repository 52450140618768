// Checkout page where the shopper gets redirected to copy the payment link.
// The user gets redirected here after clicking on `Pay with pfm`.
// Note that no sign-in is required for this page (for now.)
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import {
  fetchOrderWithToken,
  ORDER_STATE_SUCCESS,
} from "../orders/order-controller.tsx";
import InvalidOrderScreen from "../ui-components/invalid-order";
import OrderInfoIconButton from "../ui-components/OrderInfoIconButton";
import { OrderStatus } from "../common/order-ui-model";
import { useTheme } from "@mui/material";
import { logAppEvent } from "../firebase/firebase-initializer.ts";
import CircularProgressWithLockIcon from "../ui-components/CircularProgressWithLockIcon.tsx";
// @ts-ignore
import TermsAndConditionsFooter from "../ui-components/TermsAndConditionsFooter.tsx";
import AppLogo from "../ui-components/app-logo.jsx";

export default function CheckoutPage() {
  return (
    <div>
      <CheckoutPageUi />
    </div>
  );
}

function CopyLinkWithAlert(props) {
  const order = props.order;
  const orderRef = order.uid;
  const paymentLink = props.paymentLink;

  const [open, setOpen] = useState(false);

  async function copyToClipBoard(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy to clipboard: ", err);
    }
  }

  const handleClick = () => {
    copyToClipBoard(paymentLink);
    setOpen(true);
    logAppEvent("select_content", {
      content_type: "button",
      item_id: "copy_payment_link_button",
      item_name: "copy_payment_link_button",
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const primaryColor = useTheme().palette.primary.main;

  return (
    <Box
      sx={{
        display: "flex",
        border: 3,
        borderRadius: 2,
        borderColor: `${primaryColor}`,
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography sx={{ margin: 1 }} noWrap align="left" variant="body2">
          {/* Thin space unicode allows text truncation at that point */}
          {`${paymentLink}`}
        </Typography>
        <Button
          sx={{ m: 1 }}
          size="small"
          variant="contained"
          onClick={handleClick}
        >
          Copy link
        </Button>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={14000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          <Stack>
            <Typography>Payment Link copied!</Typography>
            <Typography>You can close this page if you'd like.</Typography>
          </Stack>
        </Alert>
      </Snackbar>
    </Box>
  );
}

function CheckoutPageUi(props) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const orderRef = urlSearchParams.get("order_ref");
  const token = urlSearchParams.get("token");

  const [uiModel, setUiModel] = useState({ state: "initial" });

  useEffect(() => {
    async function fetchOrder() {
      const orderUiModel = await fetchOrderWithToken(orderRef, token);
      setUiModel(orderUiModel);
    }

    fetchOrder();
  }, []);

  if (uiModel.state === "initial") {
    return <CircularProgressWithLockIcon />;
  }

  const order = uiModel.orderData;
  const paymentLinkBaseUrl = `${window.location.protocol}//${window.location.host}`;
  const paymentLink = new URL(`${paymentLinkBaseUrl}/pfm/${orderRef}`);

  if (
    uiModel.state !== ORDER_STATE_SUCCESS ||
    OrderStatus.AWAITING_PAYMENT !== order.status
  ) {
    return <InvalidOrderScreen />;
  }

  return (
    <Box
      sx={{ margin: 2 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="sm">
        <Stack spacing={3}>
          <Grid container justifyContent="center" alignItems="center">
            <AppLogo />
          </Grid>

          <Box sx={{ height: "20px" }} />

          <Grid container justifyContent="center" alignItems="center">
            <Typography align="left" variant="body1">
              {order.amount.total_formatted}
            </Typography>
            <OrderInfoIconButton order={order} />
          </Grid>

          <Typography align="center" variant="h5">
            Share Your Payment Link
          </Typography>
          <CopyLinkWithAlert order={order} paymentLink={paymentLink} />
          <Typography align="center" variant="caption">
            Anyone with the link can pay! We will process your{" "}
            {order.seller.name} order immediately
          </Typography>
          <Box sx={{ height: "5px" }} />
          <Divider />
          <TermsAndConditionsFooter />
        </Stack>
      </Container>
    </Box>
  );
}
