// This file handles logic and data related to pfm orders.

// Handles fetching stripe specific data & logic.

import {
  getFbFunctions,
  getFunctionsBaseUrl, // @ts-ignore
} from "../firebase/firebase-initializer.ts";
import { httpsCallable } from "firebase/functions";
import {
  CheckoutLinkRequestParams,
  CheckoutLinkResponse,
  FUNCTION, // @ts-ignore
} from "../models/app-models.tsx";
import { OrderUiDataResponse } from "../models/app-models";
// @ts-ignore
import { AppConst } from "../common/app-constants.ts";

export const ORDER_STATE_INVALID_INPUT = "order_state_invalid_input";
export const ORDER_STATE_DOC_NOT_FOUND = "order_state_doc_not_found";
export const ORDER_STATE_DOC_WITH_TOKEN_NOT_FOUND =
  "order_state_doc_with_token_not_found";
export const ORDER_STATE_SUCCESS = "order_state_success";

/**
 * Fetches orderData from the server and checks if it matches the specified token.
 * @param {string} orderRef pfm order reference in Firestore.
 * @param {string} token for the order in Firestore.
 * @returns {object} orderUiModel containing the order state.
 */
export async function fetchOrderWithToken(orderRef: string, token: string) {
  if (orderRef.length < 6 || !token) {
    return { state: ORDER_STATE_INVALID_INPUT };
  }

  const result: any = await fetchOrderFromServer(orderRef);

  const debug = JSON.stringify(result);

  if (!result.exists) {
    return { state: debug };
  }

  if (result.orderUiData.token !== token) {
    return { state: debug };
  }

  return { state: ORDER_STATE_SUCCESS, orderData: result.orderUiData };
}

/**
 *
 * Fetches and returns the order ui data from the server.
 * @param {string} orderRef
 * @returns {Promise<OrderUiDataResponse>}
 */
export async function fetchOrderFromServer(
  orderRef: string
): Promise<OrderUiDataResponse> {
  const functions = getFbFunctions();
  const callable = httpsCallable(functions, AppConst.CALLABLE_API_V1_ENDPOINT);
  const body = {
    function: "fetchOrder",
    orderRef: orderRef,
  };
  const result = await callable(body);
  return result.data as OrderUiDataResponse;
}

export async function fetchCheckoutLink(
  data: CheckoutLinkRequestParams
): Promise<CheckoutLinkResponse> {
  const requestUrl =
    getFunctionsBaseUrl() + "/" + AppConst.HTTP_API_V1_ENDPOINT;

  data.function = FUNCTION.CHECKOUT_LINK;

  const response = await fetch(requestUrl, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    // body data type must match "Content-Type" header
    body: JSON.stringify(data),
  });

  const res: CheckoutLinkResponse = await response.json();
  console.log("res=> " + JSON.stringify(res));

  return res;
}
