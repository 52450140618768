import React from "react";
import { Box } from "@mui/material";
import AppLogo from "./app-logo";
import StripeLogo from "../stripeLogo.png";

/**
 * AppLogo + StripeLogo
 * @returns {JSX.Element}
 */
export default function AppLogoPlusStripeLogo(): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <AppLogo />
      <h2> + </h2>
      <img
        src={StripeLogo}
        style={{ height: "40px", marginLeft: "0px" }}
        alt="stripe logo"
      />
    </div>
  );
}
