// This file handles logic and data related to the dashboard
import { httpsCallable } from "firebase/functions";
// @ts-ignore
import { getFbFunctions } from "../firebase/firebase-initializer.ts";
// @ts-ignore
import { getCurrentUser } from "../auth/auth-controller.ts";
// @ts-ignore
import { checkIsFullyOnboardedWithStripe } from "../stripe/stripe-controller.tsx";
// @ts-ignore
import { AppConst } from "../common/app-constants.ts";

export interface CheckStoreIsConnectedResponse {
  storeUrl: string;
  source: string;
  isConnected: boolean;
}

/**
 * Convenient function to determine if we should attempt
 * to fetch dashboard data or show the dashboard.
 *
 * This checks to ensure the user is authenticated and fully
 * onboarded. Otherwise if false we recommend routing to "/signed-in".
 * Since the "/signed-in" route takes care of handling the user's
 * authenticated state.
 *
 * @returns {Promise<boolean>} true if we can fetch the dashboard info.
 */
export async function canFetchDashboardInfo() {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    console.log("user is not authenticated");
    return false;
  }

  if (!currentUser.emailVerified) {
    console.log("user's email is not verified");
    return false;
  }

  if (!(await checkIsFullyOnboardedWithStripe())) {
    console.log("user is not fully onboarded");
    return false;
  }

  return true;
}

/**
 * Fetches and returns the dashboard data from the server.
 *
 * Before calling this function ensure the following:
 *  1. The current user is authenticated
 *     otherwise throws Unauthorized Error.
 *  2. The user is fully onboarded with Stripe
 *     otherwise returns incomplete data.
 *     TODO: Prefer to throw error in this case.
 *
 * @returns {*} dashboard data matching the format:
 * {
 *   displayName: "businessName",
 *   email: "email",
 *   stripeAccountId: "stripeAccountId",
 *   merchantId: "merchantId",
 *   apiKey: "apiKey",
 * }
 */
export async function fetchDashboardInfo() {
  const functions = getFbFunctions();
  const callable = httpsCallable(functions, AppConst.CALLABLE_API_V1_ENDPOINT);
  const result = await callable({ function: "fetchDashboardInfo" });
  return result.data;
}

/**
 * Fetch and return the business info from the server.
 * @param {string} merchantId the business reference
 * @returns {*} businessInfo data matching the format:
 * {
 *   displayName: "businessName",
 *   exists: true,
 *   logoUrl: "https://example.com/hello.png",
 * }
 */
export async function fetchBusinessInfo(merchantId) {
  const functions = getFbFunctions();
  const callable = httpsCallable(functions, AppConst.CALLABLE_API_V1_ENDPOINT);
  const body = {
    function: "fetchBusinessInfo",
    merchantId: merchantId,
  };
  const result = await callable(body);
  return result.data;
}

/**
 * Check if the storeUrl is connected to pfm
 * @param {string} storeUrl
 * @param {string} source
 * @returns {Promise<CheckStoreIsConnectedResponse>}
 */
export async function checkStoreIsConnected(
  storeUrl: string,
  source: string
): Promise<CheckStoreIsConnectedResponse> {
  const functions = getFbFunctions();
  const callable = httpsCallable(functions, AppConst.CALLABLE_API_V1_ENDPOINT);
  const body = {
    function: "checkStoreIsConnected",
    storeUrl: storeUrl,
    source: source,
  };
  const result = await callable(body);
  return result.data as CheckStoreIsConnectedResponse;
}
