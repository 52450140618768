import { useState, useEffect } from "react";
import * as React from "react";
import { Typography, Stack, Grid, Divider, Button } from "@mui/material";
import Container from "@mui/material/Container";
import { Box } from "@mui/system";
// @ts-ignore
import TermsAndConditionsFooter from "../ui-components/TermsAndConditionsFooter.tsx";
// @ts-ignore
import CircularProgressWithLockIcon from "../ui-components/CircularProgressWithLockIcon.tsx";
import { Shopper, ShippingAddress } from "../models/app-models";
import { Elements } from "@stripe/react-stripe-js";
import { AddressElement } from "@stripe/react-stripe-js";
import { loadStripeForConnectAccount } from "../stripe/payment-element-form";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  ContactOption,
  StripeAddressElementChangeEvent,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import AppLogo from "../ui-components/app-logo";
// @ts-ignore
import { getCurrentUser, signOutCurrentUser } from "../auth/auth-controller.ts";

type StoreCheckoutPageUiModel = {
  shopper: Shopper;
  addresses: ShippingAddress[]; // user shipping addresses
};

const stripePromise = loadStripeForConnectAccount("");

/**
 * Component for adding items from chrome extension or other external sources.
 * Expects url parameter containing JSON data payload.
 */
export default function StoreCheckoutPage() {
  const href = window.location.href;

  const [uiData, setUiData] = useState<StoreCheckoutPageUiModel>();

  async function fetchData() {
    try {
      // Verify user is signed in
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        window.location.assign("/store-signin?destination=" + href);
        return;
      }

      // Get the shipping address if any from the store's checkout page
      // empty/incomplete shipping addresses are ignored automatically by Stripe
      // Address Element contact options.
      const urlSearchParams = new URLSearchParams(window.location.search);
      const shippingString = urlSearchParams.get("shipping") + "";
      let shipping: any;
      try {
        shipping = JSON.parse(shippingString);
      } catch (error) {
        console.log("did not parse shipping from href param");
        shipping = JSON.parse("{}");
      }

      setUiData({
        addresses: [shipping],
        shopper: {
          displayName: currentUser.displayName!,
          email: currentUser.email!,
        },
      });
    } catch (err) {
      console.log("page error: " + JSON.stringify(err));
    }
  }

  useEffect(() => {
    fetchData();
    listenToPostedMessages();
  }, []);

  if (!uiData) {
    return <CircularProgressWithLockIcon />;
  }

  return (
    <Container maxWidth="md">
      <Box
        sx={{ m: 2 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Stack>
          <AppLogo />
          <Typography margin={0.5} variant="body2">
            Welcome, {uiData.shopper.email}
          </Typography>
          <Button
            size="small"
            sx={{ maxWidth: "150px" }}
            onClick={async () => {
              await signOutCurrentUser();
              window.location.assign(href);
            }}
          >
            Not you? Sign Out
          </Button>

          <Box sx={{ height: "10px" }} />
          <Divider />
          <Box sx={{ height: "30px" }} />

          <ShippingInfoContainer />

          <Box sx={{ height: "30px" }} />
          <TermsAndConditionsFooter />
        </Stack>
      </Box>
    </Container>
  );

  function ShippingInfoContainer(props: any) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Stack>
          <Typography variant="h5">Shipping Information</Typography>
          <Box sx={{ height: "20px" }} />

          <AddressElementForm />

          <Box sx={{ height: "20px" }} />
        </Stack>
      </Grid>
    );
  }

  /**
   * Builds the address form react component using stripe address elememt
   * @param {ShippingAddress} userShippingAddresses
   * @returns {JSX.Element}
   */
  function AddressElementForm() {
    const shopper = uiData!.shopper;
    const [isElementReady, setElementReady] = useState(false);
    const [isElementLoaded, setElementLoaded] = useState(false);
    const [elementChangeEvent, setElementChangeEvent] =
      useState<StripeAddressElementChangeEvent>();

    const userAddresses = uiData!.addresses;
    let selectedAddress: ShippingAddress | undefined;

    if (!isElementReady) {
      console.log("init selectedAddress");
      selectedAddress = userAddresses.length ? userAddresses[0] : undefined;
    } else {
      // reset initial selectedAddress since element changed
      console.log("reset selectedAddress to undefined");
      selectedAddress = undefined;

      if (elementChangeEvent?.complete) {
        console.log("init selectedAddress with elementValue");
        // use the newly changed address
        const elementValue = elementChangeEvent!.value;
        const address = elementValue.address;
        const nameSplit = elementValue.name.split(" ");
        const firstName = nameSplit[0].trim();
        const lastName = nameSplit.length > 1 ? nameSplit[1].trim() : "";

        selectedAddress = {
          firstName: firstName,
          lastName: lastName,
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          state: address.state,
          postalCode: address.postal_code,
          countryCode: address.country,
          email: shopper.email,
        };
      }
    }

    // unused variable fixes bug:
    const [isLoading, setIsLoading] = useState(false);
    const disableButton = !selectedAddress;
    const buttonText = isLoading ? "Processing" : "Continue";

    const handleSubmit = async (event) => {
      // don't refresh the page.
      event.preventDefault();

      try {
        setIsLoading(true);
        const msg = {
          action: "payforme-post-shipping-address", // expected by opener window
          shipping: selectedAddress,
          shopper: shopper,
        };
        postMessageToWindow(msg);
      } catch (error) {
        console.log("error: " + JSON.stringify(error));
        setIsLoading(false);
      }
    };

    const options: StripeElementsOptions = {
      appearance: {
        theme: "stripe",
      },
    };

    const contactOptions: ContactOption[] = [];

    if (selectedAddress) {
      const contactOption1 = {
        name: selectedAddress!.firstName + " " + selectedAddress!.lastName,
        address: {
          line1: selectedAddress!.line1,
          line2: selectedAddress!.line2 ?? "",
          city: selectedAddress!.city,
          state: selectedAddress!.state,
          postal_code: selectedAddress!.postalCode,
          country: selectedAddress!.countryCode,
        },
      };
      // const contactOption2 = {
      //   name: "Mary Doe",
      //   address: {
      //     line1: "354 Crab Point Blvd",
      //     line2: "",
      //     city: "Hyattsville",
      //     state: "MD",
      //     postal_code: "20785",
      //     country: "US",
      //   },
      // };

      contactOptions.push(contactOption1);
      // contactOptions.push(contactOption2);
    }

    return (
      <Elements stripe={stripePromise} options={options}>
        <form>
          <Stack>
            <Box sx={{ height: "10px" }} />

            <Box hidden>
              {
                // SetTimeout fixes AddressElement bug where onChange event
                // does not fire unelss some state changes. This ui is never shown.
                // https://stackoverflow.com/a/75343865/3144836
                setTimeout(() => {
                  setElementLoaded(true);
                }, 500)
              }
            </Box>

            <AddressElement
              options={{
                mode: "shipping",
                contacts: contactOptions,
                // defaultValues: {
                //   name: "Jane Doe " + isElementLoaded,
                //   address: {
                //     line1: "354 Oyster Point Blvd",
                //     line2: "",
                //     city: "South San Francisco",
                //     state: "CA",
                //     postal_code: "94080",
                //     country: "US",
                //   },
                // },
              }}
              onChange={(event) => setElementChangeEvent(event)}
              onReady={(event) => {
                console.log("onready=> " + event);
                setElementReady(true);
              }}
            />
            <Box sx={{ height: "10px" }} />
            <LoadingButton
              onClick={handleSubmit}
              loading={isLoading}
              variant="contained"
              disableElevation={true}
              disabled={disableButton}
              loadingPosition="end"
              endIcon={<></>}
            >
              {buttonText}
            </LoadingButton>
          </Stack>
        </form>
      </Elements>
    );
  }
}

/**
 * posts message to the window opener
 * @param {any} msg to post
 */
async function postMessageToWindow(msg: any) {
  // console.log(`${JSON.stringify(msg)}`);

  if (window && window.opener) {
    console.log("posting message to window.opener...");
    window.opener.postMessage(msg, "*");
  }
}

/**
 * Listen to messages posted by the opener window
 */
function listenToPostedMessages() {
  window.addEventListener(
    "message",
    (event) => {
      const msgData = event["data"];
      if (msgData?.action === "payforme-error-fetching-payment-link") {
        // TODO: Handle error posted from the e-commerce store window
        // E.g. Display something went wrong or some error message
      }
    },
    false
  );
}
