// Sign in page shown to the shopper from the store to continue
// checking to let someone else pay.

import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import AppLogo from "../ui-components/app-logo";
// @ts-ignore
import { getFbAuth, getFbUiConfig } from "../firebase/firebase-initializer.ts";

export default function StoreSignInPage() {
  const DEFAULT_DESTINATION = "/signed-in";
  const href = window.location.href;
  let destination = new URL(href).searchParams.get("destination");
  if (!destination) {
    console.log("destination not found in " + href);
  }

  return (
    <Box
      sx={{ margin: 2 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="sm">
        <Stack spacing={1}>
          <AppLogo />
          <Typography variant="h4">Sign-In or Create an Account</Typography>
          <Typography variant="subtitle1">
            Easiest way to let your friends or family pay for you.
          </Typography>
          <Box sx={{ height: "10px" }} />
          <Divider />
          <StyledFirebaseAuth
            uiConfig={getFbUiConfig(destination)}
            firebaseAuth={getFbAuth()}
          />
        </Stack>
      </Container>
    </Box>
  );
}
