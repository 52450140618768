// Page redirected to after payment.
// Payment is not guaranteed to succeed and anyone can reach this
// public url so be sure to confirm the payment status.
import React, { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Box, CircularProgress } from "@mui/material";
import { fetchPaymentIntent } from "../stripe/stripe-controller.tsx";
import { loadStripeForConnectAccount } from "../stripe/payment-element-form";
import { retrieveRecieptUrl } from "../stripe/stripe-controller.tsx";

export default function PaymentReturn() {
  // Retrieve the "payment_intent_client_secret" query parameter appended to
  // your return_url by Stripe.js
  const urlSearchParams = new URLSearchParams(window.location.search);
  const clientSecret = urlSearchParams.get("payment_intent_client_secret");
  const orderRef = urlSearchParams.get("order_ref");
  const stripeAccount = urlSearchParams.get("stripe_account");

  const stripePromise = loadStripeForConnectAccount(stripeAccount);
  return (
    <Elements stripe={stripePromise}>
      <PaymentStatusHandler
        clientSecret={clientSecret}
        orderRef={orderRef}
        stripeAccount={stripeAccount}
      />
    </Elements>
  );
}

function PaymentStatusHandler(props) {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);

  const clientSecret = props.clientSecret;
  const orderRef = props.orderRef;
  const stripeAccount = props.stripeAccount;

  useEffect(() => {
    if (!stripe) {
      console.log("unexpected state: no stripe");
      return;
    }

    if (!clientSecret) {
      console.log("unexpected state: no clientSecret");
      return;
    }

    console.log("unexpected states succeeded");

    async function checkPaymentStatus() {
      try {
        const { paymentIntent } = await stripe.retrievePaymentIntent(
          clientSecret
        );

        console.log("paymentIntent===> " + JSON.stringify(paymentIntent));

        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case "succeeded":
            // Redirect to the receiptUrl page.
            // First fetch the full paymentIntent containing the receipt_url
            // since `stripe.retrievePaymentIntent` from stripe.js returns the partial payload
            const { receipt_url } = await retrieveRecieptUrl({
              stripe_account: stripeAccount,
              payment_intent_id: paymentIntent.id,
            });

            window.location.assign(receipt_url);
            break;

          case "processing":
            setMessage(
              orderRef,
              "Your payment is being processed. We will update you when your payment is confirmed."
            );
            break;

          case "requires_payment_method":
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            redirectToPaymentPageWithError(
              orderRef,
              "Payment failed. Please try another payment method."
            );
            break;

          default:
            redirectToPaymentPageWithError(orderRef, "Something went wrong.");
            break;
        }
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
    }

    checkPaymentStatus();
  }, [stripe]);

  if (!stripe || !message) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <main style={{ padding: "1rem 0" }}>
      <h1>{message}</h1>
    </main>
  );
}

function redirectToPaymentPageWithError(orderRef, errorMessage) {
  const paymentPageUrlWithErrorParam = `${window.location.protocol}//${window.location.host}/pfm2/${orderRef}?payment_status_error=${errorMessage}`;
  window.location.assign(paymentPageUrlWithErrorParam);
}
