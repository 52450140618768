/**
 * App constants
 */
export class AppConst {
  public static readonly APP_NAME = "Payforme";
  public static readonly HTTP_API_V1_ENDPOINT = "apiV1";
  public static readonly CALLABLE_API_V1_ENDPOINT = "callableApiV1";
  public static readonly WOO_AUTH_API_ENDPOINT = "/wc-auth/v1/authorize";
}

/**
 * App Routes
 */
export class AppRoute {
  public static readonly SIGNIN = "/signin";
  public static readonly SIGNED_IN = "/signed-in";
  public static readonly DASHBOARD = "/dashboard";
  public static readonly VERIFY_EMAIL = "/verify-email";
  public static readonly CONNECT_WOO_STORE = "/connect-woo-store";
  public static readonly CONNECT_WOO_STORE_MSG = "/connect-woo-store-msg";
}
