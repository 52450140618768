import React, { useState, useEffect } from "react";
import ".././App.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { PaymentElementForm } from "../stripe/payment-element-form";
import InvalidOrderScreen from "../ui-components/invalid-order";
import {
  Button,
  Container,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { OrderStatus } from "../common/order-ui-model";
// @ts-ignore
import TermsAndConditionsFooter from "../ui-components/TermsAndConditionsFooter.tsx";
// @ts-ignore
import { fetchOrderFromServer } from "../orders/order-controller.tsx";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import PurchaseDetails from "../ui-components/purchase-details";
import AppLogo from "../ui-components/app-logo";
import { OrderUiDataResponse } from "../models/app-models";
// @ts-ignore
import { AppConst } from "../common/app-constants.ts";

const secondaryTextColor = "#797979";

// The payment link page where the payer reviews and pays for the order.
// E.g. http://pay.payforme.io/pfm/orderRef
export default function PaymentLinkPageCustom() {
  var pathName = window.location.pathname;
  var orderRef = pathName.substring(pathName.lastIndexOf("/") + 1);

  const [uiModel, setUiModel] = useState<OrderUiDataResponse>();

  useEffect(() => {
    async function fetchOrder() {
      const orderUiModel: OrderUiDataResponse = await fetchOrderFromServer(
        orderRef
      );
      console.log("***** " + JSON.stringify(orderUiModel));
      setUiModel(orderUiModel);
    }

    fetchOrder();
  }, []);

  if (!uiModel) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  const orderData = uiModel.orderUiData;

  if (!uiModel.exists || OrderStatus.AWAITING_PAYMENT !== orderData?.status) {
    return <InvalidOrderScreen />;
  }

  return <AppBox uiModel={uiModel} />;
}

function AppBox(props) {
  const uiModel = props.uiModel as OrderUiDataResponse;
  const order = uiModel.orderUiData!;
  const isSourceDirectPay = order.source === "directpay";
  const showShopperName = !isSourceDirectPay;
  const sellerName = order.seller.name;
  const shopperDisplayName = order.shopper?.displayName;
  const totalFormatted = order.amount.total_formatted;
  const firstImageUrl: string | undefined = order.line_items.find(
    (item) => item.images?.length
  )?.images![0];

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (prop) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{ m: 2 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="sm">
        <Stack spacing={4}>
          <Grid container justifyContent="center" alignItems="center">
            <AppLogo />
            <IntroMessage />
          </Grid>
          <PaymentStatusErrorMessage />
          <Stack justifyContent="center" alignItems="center" spacing={1}>
            <Typography
              align="center"
              variant="h5"
              style={{ color: secondaryTextColor }}
            >
              {showShopperName
                ? `You're paying for ${shopperDisplayName}'s purchase on ${sellerName}`
                : `Pay ${sellerName}`}
            </Typography>
            {firstImageUrl && <img width={"140px"} src={firstImageUrl}></img>}
            <Typography align="center" variant="h3">
              {totalFormatted}
            </Typography>
            <Button
              onClick={handleClickOpen}
              size="small"
              aria-label="view details"
              variant="outlined"
              sx={{ borderRadius: 28 }}
            >
              View Details
            </Button>
          </Stack>
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
          >
            <Grid container justifyContent="flex-end">
              <Button>
                <CloseIcon fontSize="large" onClick={handleClose} />
              </Button>
            </Grid>
            <DialogTitle>
              <Typography variant="body1" fontWeight="bold">
                Purchase Details
              </Typography>
            </DialogTitle>
            <DialogContent>
              <PurchaseDetails order={order} />
            </DialogContent>
          </Dialog>
          <Divider>Pay below</Divider>
          <PaymentElementForm order={order} />
          <Box sx={{ height: "50px" }} />
          <TermsAndConditionsFooter />
        </Stack>
      </Container>
    </Box>
  );
}

function IntroMessage() {
  return (
    <div style={{ padding: "10px" }}>
      <Stack spacing={-1}>
        <Typography align="left" variant="subtitle2">
          <Box sx={{ fontWeight: "medium", margin: 0 }}>
            {AppConst.APP_NAME} lets you pay for your friends and family. Don't
            worry, payment is made directly to the seller.
          </Box>
        </Typography>
      </Stack>
    </div>
  );
}

function PaymentStatusErrorMessage() {
  // Retrieves the "payment_status_error" query parameter appended to
  // the url by the `payment-return-url` if payment does not succeed.
  // This allows the user can re-enter payment details if necessary
  const paymentStatusError = new URLSearchParams(window.location.search).get(
    "payment_status_error"
  );

  if (!paymentStatusError) {
    return <></>;
  }

  return (
    <Typography align="center" variant="h5" color="#f44336">
      {paymentStatusError}
    </Typography>
  );
}
