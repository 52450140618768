/// Handles the entire pass-through condition for a user to be considered
/// authenticated before accessing pfm App/Dashboard.

import React, { useState, useEffect } from "react";
import { Stack, Typography, CircularProgress, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { getCurrentUser } from "../auth/auth-controller.ts";
import {
  checkIsFullyOnboardedWithStripe,
  createStripeAccountLinks, // @ts-ignore
} from "../stripe/stripe-controller.tsx";
// @ts-ignore
import { createUserIfNotExists } from "../user/user-controller.ts";
// @ts-ignore
import { AppRoute } from "../common/app-constants.ts";
// @ts-ignore
import BeforeStripeOnboardingPage from "./before-stripe-onboarding.tsx";

/**
 * Page handles the logic to verify the user is signed in and fully onboarded
 * Otherwise redirects the user to Stripe onboarding
 */
export default function SignedInPage() {
  const query = new URLSearchParams(useLocation().search);
  const isRefreshUrl = query.get("refresh") === "true";
  // loading state displayed in the ui
  const [data, setData] = useState("");

  useEffect(() => {
    async function fetchData() {
      setData("authenticating...");
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        window.location.assign(AppRoute.SIGNIN);
        return;
      }

      setData("verifying email...");
      if (!currentUser.emailVerified) {
        window.location.assign(AppRoute.VERIFY_EMAIL);
        return;
      }

      setData("verifying user info...");
      await createUserIfNotExists("business");

      setData("verifying business account...");
      if ((await checkIsFullyOnboardedWithStripe()) === true) {
        window.location.assign(AppRoute.DASHBOARD);
        return;
      }

      setData("preparing for account setup...");
      if (isRefreshUrl) {
        const { url: accountLinksUrl } = await createStripeAccountLinks();
        window.location.assign(accountLinksUrl);
      }
    }

    fetchData();
  }, []);

  if (data === "preparing for account setup..." && !isRefreshUrl) {
    return <BeforeStripeOnboardingPage />;
  }

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack spacing={1} alignItems="center">
        <Typography variant="caption" align="center">
          {`${data}`}
        </Typography>
        <CircularProgress />
      </Stack>
    </Box>
  );
}
