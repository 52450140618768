import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AppLogo from "./app-logo";

export default function InvalidOrderScreen() {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="sm">
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <AppLogo />
            <Typography gutterBottom variant="body1">
              This link has expired. This means that the link is invalid or has
              been processed.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
