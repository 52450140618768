import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button, Container, Divider } from "@mui/material";
import AppLogo from "../ui-components/app-logo";
// @ts-ignore
import TermsAndConditionsFooter from "../ui-components/TermsAndConditionsFooter.tsx";
// @ts-ignore
import { AppConst, AppRoute } from "../common/app-constants.ts";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { checkStoreIsConnected } from "../dashboard/dashboard-controller.ts";
// @ts-ignore
import CircularProgressWithLockIcon from "../ui-components/CircularProgressWithLockIcon.tsx";

type ConnectWooStoreUiModel = {
  screen: string;
  storeUrl: string;
};

/**
 * Page shows intro or success message related to connecting Woo store.
 * E.g. the intro message explains why we need to connect the store.
 * and success says something like Congratulations! You've connected your store.
 * @param {any} {asyncCheckStoreIsConnected} - exposes a convenient way to mock the checkStoreIsConnected function
 * @returns {JSX.Element}
 */
export default function ConnectWooStoreMessagePage({
  asyncCheckStoreIsConnected = checkStoreIsConnected,
}: any): JSX.Element {
  const query = new URLSearchParams(useLocation().search);
  const storeUrl = query.get("store_url");
  const success = query.get("success");
  const queryHasSuccess = query.has("success");
  const [uiModel, setUiModel] = useState<ConnectWooStoreUiModel>();
  const [pageError, setPageError] = useState<string>();

  useEffect(() => {
    async function initializeScreen() {
      if (!storeUrl) {
        setPageError("store_url not found");
        return;
      }

      let screen: string;
      if (queryHasSuccess) {
        screen = success === "1" ? "success" : "intro";
      } else {
        const res = await asyncCheckStoreIsConnected(storeUrl, "woocommerce");
        screen = res.isConnected ? "already_connected" : "intro";
      }

      setUiModel({
        screen: screen,
        storeUrl: storeUrl,
      });
    }
    initializeScreen();
  }, []);

  if (pageError) {
    return <>{pageError}</>;
  }

  if (!uiModel) {
    return <CircularProgressWithLockIcon />;
  }

  if (uiModel.screen === "intro") {
    return <ConnectWooStoreIntroScreen uiModel={uiModel} />;
  }

  if (uiModel.screen === "success") {
    return <ConnectWooStoreSuccessScreen uiModel={uiModel} />;
  }

  if (uiModel.screen === "already_connected") {
    return <ConnectWooStoreAlreadyConnectedSScreen uiModel={uiModel} />;
  }

  return <>unreachable</>;
}

function ConnectWooStoreIntroScreen(props: {
  uiModel: ConnectWooStoreUiModel;
}) {
  const storeUrl = props.uiModel.storeUrl;

  return (
    <Container maxWidth="sm">
      <Stack spacing={4}>
        <AppLogo />
        <Typography variant="h4">
          Let's Help You Connect Your WooCommerce Store
        </Typography>

        <Typography>
          Connect {<strong>{storeUrl}</strong>} to enable {AppConst.APP_NAME}{" "}
          ensure items are in stock and create your orders when payment is
          received.
        </Typography>
        <Typography>
          Already have a {AppConst.APP_NAME} business account? Continue to sign
          in or create a new account.
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            const url = `${AppRoute.CONNECT_WOO_STORE}?store_url=${storeUrl}`;
            window.location.assign(url);
          }}
        >
          CONTINUE
        </Button>
        <Box sx={{ height: "50px" }} />
        <Divider />
        <TermsAndConditionsFooter />
      </Stack>
    </Container>
  );
}

function ConnectWooStoreSuccessScreen(props: {
  uiModel: ConnectWooStoreUiModel;
}) {
  const storeUrl = props.uiModel.storeUrl;

  return (
    <Container maxWidth="sm">
      <Stack spacing={4}>
        <AppLogo />
        <Typography variant="h4">
          🎉 Congrats! Your store is now successfully connected!{" "}
        </Typography>

        <Typography>
          Head over to your WooCommerce settings to enable {AppConst.APP_NAME}.
        </Typography>
        <Typography>
          Feel free to close this page whenever you're ready. We're all set on
          this end!
        </Typography>
        <Box sx={{ height: "50px" }} />
        <Divider />
        <TermsAndConditionsFooter />
      </Stack>
    </Container>
  );
}

function ConnectWooStoreAlreadyConnectedSScreen(props: {
  uiModel: ConnectWooStoreUiModel;
}) {
  const storeUrl = props.uiModel.storeUrl;

  return (
    <Container maxWidth="sm">
      <Stack spacing={4}>
        <AppLogo />
        <Typography variant="h4">
          🎉 Congrats! your store is already connected!{" "}
        </Typography>

        <Typography>{<strong>{storeUrl}</strong>} is good to go.</Typography>
        <Typography>
          Feel free to close this page whenever you're ready. We're all set on
          this end!
        </Typography>
        <Box sx={{ height: "50px" }} />
        <Divider />
        <TermsAndConditionsFooter />
      </Stack>
    </Container>
  );
}
