import React, { useState, useEffect } from "react";
// @ts-ignore
import { AppConst, AppRoute } from "../common/app-constants.ts";
// @ts-ignore
import CircularProgressWithLockIcon from "../ui-components/CircularProgressWithLockIcon.tsx";
// @ts-ignore
import { canFetchDashboardInfo } from "../dashboard/dashboard-controller.ts";
// @ts-ignore
import { getCurrentUser } from "../auth/auth-controller.ts";
// @ts-ignore
import { getHttpApiV1RequestUrl } from "../firebase/firebase-initializer.ts";

// Page to connect Woo store to pfm. Merchant must be authenticated and fully onboarded.
export default function ConnectWooStorePage() {
  const [pageError, setPageError] = useState<string>();

  useEffect(() => {
    async function asyncPerformUseEffect() {
      const pageUrl = new URL(window.location.href);
      const storeUrl = pageUrl.searchParams.get("store_url");
      if (!storeUrl) {
        setPageError("store_url is missing");
        return;
      }

      const currentUser = await getCurrentUser();
      if (!currentUser) {
        // user is not authenticated
        const destination = `${AppRoute.CONNECT_WOO_STORE}?store_url=${storeUrl}`;
        const url = `${AppRoute.SIGNIN}?destination=${destination}`;
        window.location.replace(url);
        return;
      }

      const canFetch = await canFetchDashboardInfo();
      if (!canFetch) {
        // user is not fully onboarded.
        window.location.replace(AppRoute.SIGNED_IN);
        return;
      }

      // TODO: Get authToken
      const authToken = await currentUser.getIdToken(/* forceRefresh */ true);
      const endpoint = AppConst.WOO_AUTH_API_ENDPOINT;
      const returnUrl = `${pageUrl.origin}${AppRoute.CONNECT_WOO_STORE_MSG}?store_url=${storeUrl}`;
      const callbackUrl = `${getHttpApiV1RequestUrl()}?function=wooApiKeysCallback&store_url=${storeUrl}`; // url to receive api keys via POST

      const params = new URLSearchParams({
        app_name: AppConst.APP_NAME,
        scope: "read_write",
        user_id: authToken,
        return_url: returnUrl,
        callback_url: callbackUrl,
      });

      // URLSearchParams automatically encodes the parameters
      const wooAuthUrl = `${storeUrl}${endpoint}?${params.toString()}`;
      window.location.replace(wooAuthUrl);
    }

    asyncPerformUseEffect();
  }, []);

  if (pageError) {
    return <>{pageError}</>;
  }

  return <CircularProgressWithLockIcon />;
}
