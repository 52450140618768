import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import PurchaseDetails from "./purchase-details";
import Typography from "@mui/material/Typography";
import { logAppEvent } from "../firebase/firebase-initializer.ts";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";

export default function OrderInfoIconButton(props) {
  const order = props.order;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    logAppEvent("select_content", {
      content_type: "button",
      item_id: "order_info_icon_button",
      item_name: "order_info_icon_button",
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen} aria-label="view order info">
        <InfoIcon color="primary" />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <Grid container justifyContent="flex-end">
          <Button margin="150px">
            <CloseIcon fontSize="large" onClick={handleClose} />
          </Button>
        </Grid>
        <DialogTitle>
          <Typography variant="body1" fontWeight="bold">
            Purchase Details
          </Typography>
        </DialogTitle>
        <DialogContent>
          <PurchaseDetails order={order} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
