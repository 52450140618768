import React from "react";
import { Typography, Stack, Divider, Grid } from "@mui/material";
// @ts-ignore
import { AppConst } from "../common/app-constants.ts";

/*
  Footer with clickable links to pfm Privacy & Terms 
*/
export default function TermsAndConditionsFooter() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ padding: "30px" }}
    >
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" style={{ color: "grey" }}>
          Powered by {<></>}
          <a
            style={{
              textDecoration: "none",
              color: "grey",
            }}
            target="_blank"
            href="https://payforme.io/"
          >
            <b>{AppConst.APP_NAME}</b>
          </a>
        </Typography>

        <Divider orientation="vertical" flexItem />

        <Typography variant="body2">
          <a
            style={{
              textDecoration: "underline dotted",
              color: "grey",
            }}
            target="_blank"
            href="https://payforme.io/terms-and-conditions"
          >
            Terms
          </a>
        </Typography>

        <Typography variant="body2">
          <a
            target="_blank"
            style={{ textDecoration: "underline dotted", color: "grey" }}
            href="https://payforme.io/privacy-policy"
          >
            Privacy
          </a>
        </Typography>
      </Stack>
    </Grid>
  );
}
