import React from "react";
// @ts-ignore
import { AppConfig } from "../common/app-config.ts";
import { Box, Stack } from "@mui/material";

const DebugBanner = ({ message }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        padding: "4px 8px",
        transform: "translate(40%, -105%) rotate(45deg)",
        transformOrigin: "0% 100%",
        background: "#42a5f5",
        color: "#fff",
        zIndex: 9999,
        fontWeight: "bold",
        fontSize: "11px",
        lineHeight: 1,
        letterSpacing: "0.5px",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
        textShadow: "1px 1px 1px rgba(0, 0, 0, 0.3)",
      }}
    >
      <Stack direction="row">
        <Box width={10} />
        {message}
        <Box width={25} />
      </Stack>
    </div>
  );
};

/**
 * Returns a DebugBanner or empty if in prod environment.
 */
const OptionalDebugBanner = ({ message = "SANDBOX" }) => {
  // payForMeLocalizedData is from wc plugin
  const showDebugBanner =
    (window as any)?.payForMeLocalizedData?.environment !== "prod";

  if (AppConfig.IS_PROD_ENVIRONMENT) {
    return <></>;
  }

  return <DebugBanner message={message} />;
};

export default OptionalDebugBanner;
