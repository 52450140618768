import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
// @ts-ignore
import { AppConst, AppRoute } from "../common/app-constants.ts";
// @ts-ignore
import TermsAndConditionsFooter from "../ui-components/TermsAndConditionsFooter.tsx";
// @ts-ignore
import { createStripeAccountLinks } from "../stripe/stripe-controller.tsx";
// @ts-ignore
import { getCurrentUser, signOutCurrentUser } from "../auth/auth-controller.ts";
// @ts-ignore
import CircularProgressWithLockIcon from "../ui-components/CircularProgressWithLockIcon.tsx";
// @ts-ignore
import AppLogoPlusStripeLogo from "../ui-components/AppLogoPlusStripeLogo.tsx";

type BeforeStripeOnboardinUiModel = {
  email: string;
};

// Page to show before stripe onboarding.
// There's no route for this but it is a full page component
export default function BeforeStripeOnboardingPage() {
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [uiModel, setUiModel] = React.useState<BeforeStripeOnboardinUiModel>();

  useEffect(() => {
    async function performAsync() {
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        window.location.replace(AppRoute.SIGNIN);
        return;
      }

      setUiModel({
        email: currentUser.email!,
      });
    }
    performAsync();
  }, []);

  if (!uiModel) {
    return <CircularProgressWithLockIcon />;
  }

  return (
    <Container maxWidth="sm">
      <AppLogoPlusStripeLogo />
      <Stack spacing={4}>
        <Box>
          <Typography variant="body2">Welcome, {uiModel.email}</Typography>
          <Box>
            <Button
              size="small"
              sx={{
                paddingLeft: "0px",
                minWidth: "0",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
              onClick={async () => {
                await signOutCurrentUser();
                window.location.replace(AppRoute.SIGNIN);
              }}
            >
              Not you? Sign Out
            </Button>
          </Box>
        </Box>
        <Typography variant="h4">
          Welcome to {AppConst.APP_NAME}! You will need to complete Stripe setup
          before you can fully use our platform.
        </Typography>
        <Typography>
          Stripe is our secure payment partner that allows you to accept
          payments from customers seamlessly. Don't worry, Stripe's onboarding
          process is quick and easy!
        </Typography>
        <LoadingButton
          variant="contained"
          loading={buttonLoading}
          startIcon={<></>}
          onClick={async () => {
            setButtonLoading(true);
            const { url: accountLinksUrl } = await createStripeAccountLinks();
            window.location.replace(accountLinksUrl);
          }}
        >
          CONTINUE
        </LoadingButton>
        <Box sx={{ height: "20px" }} />
        <Divider />
        <TermsAndConditionsFooter />
      </Stack>
    </Container>
  );
}
