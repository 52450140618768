// Contains app types and modules

/**
 * Supported server function names passed via POST body["function"]
 */
export class FUNCTION {
  public static readonly CHECKOUT_LINK = "checkoutLink";
}

/**
 * Request body Params for fetching checkoutLink with bizRef info
 */
export interface CheckoutLinkRequestParams {
  function: string;
}

/**
 * Request body Params for fetching checkoutLink with bizRef info
 */
export interface BizRefCheckoutLinkCreateParams
  extends CheckoutLinkRequestParams {
  source: string;
  description: string;
  currency: string;
  bizRef: string;
  amount: number;
  metaData?: any;
}

/**
 * Response from fetching the checkoutLink
 */
export type CheckoutLinkResponse = {
  data: {
    checkoutLink: string;
    paymentLink: string;
  };
};

/**
 * Response containing the payment link or stripe session url
 */
export type CheckoutSessionResponse = {
  isPaymentLinkValid: boolean;
  orderStatus: string;
  sessionUrl?: string | null;
  reason?: string;
  lineItemsOutOfStock?: LineItem[];
};

/**
 * Purchase line item
 */
export type LineItem = {
  name: string;
  unitPrice: number;
  unitPriceFormatted: string;
  totalPrice?: number;
  totalPriceFormatted: string;
  quantity: number;
  currency: string;
  description?: string;
  tax?: number;
  id?: string;
  variationId?: string;
  images?: string[];
};

export type CreateSessionResponse = {
  isPaymentLinkValid: boolean;
  orderStatus: string;
  sessionUrl?: string | null;
  reason?: string;
  lineItemsOutOfStock?: LineItem[];
  sellerName?: string;
  sellerWebsite?: string;
};

export type DirectPaymentUIModel = {
  merchantExists: boolean;
  businessLogoUrl: string;
  businessName: string;
  isLoading: boolean;
};

/**
 * Param body to CreateCheckoutSession for DirectPay
 */
export type CreateCheckoutSessionParamForDirectPay = {
  merchantId: string;
  type: string;
  totalAmount: number;
  description: string;
  currency: string;
  successUrl: string;
  cancelUrl: string;
};

/**
 * Param body to CreateCheckoutSession for PfmOrder
 */
export type CreateCheckoutSessionParamForPfmOrder = {
  type: string;
  order_ref: string;
};

/**
 * Data from extension
 */
export type ExtensionData = {
  website: string;
  cartItems: ExtensionDataCartItem[];
  totalItemCount: number;
};

export type ExtensionDataCartItem = {
  name: string;
  productId: string;
  currency: string;
  price: string;
  subtotal: string;
  quantity: string;
  images: string[];
  productLink: string;
  productVariation: ExtensionDataProductVariation[];
};

export type ExtensionDataProductVariation = {
  name: string;
  value: string;
};

/**
 * Param body to create CheckoutLink from browser extension
 */
export interface CheckoutLinkParamForBrowserExtension
  extends CheckoutLinkRequestParams {
  website: string;
  source: string;
  totalItemCount: number;
  needsShipping: boolean;
  shippingAddress: ExtensionDataShippingAddress;
  cartItems: ExtensionDataCartItem[];
}

/**
 * The shipping address from the browser extension
 */
export type ExtensionDataShippingAddress = {
  firstName: string;
  lastName: string;
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  email: string;
};

/**
 * The shipping address from the browser extension
 * Either one of sessionId or payment_intent_id is required.
 */
export type RetrieveReceiptParam = {
  stripe_account: string;
  session_id?: string;
  payment_intent_id?: string;
};

/**
 * Order Response from the server
 */
export type OrderUiDataResponse = {
  exists: boolean;
  orderUiData?: {
    id: string;
    currency: string;
    amount: OrderAmount;
    line_items: LineItem[];
    seller: Seller;
    token: string;
    shopper?: Shopper;
    shipping?: ShippingAddress;
    status: string;
    source: string;
    merchant_stripe_account: string;
    stripe_payment_intent_client_secret?: string;
  };
};

export interface OrderAmount {
  total_formatted: string;
  tax_formatted: string;
  shipping_formatted: string;
  total: number;
  subtotal: number;
  tax: number;
  shipping: number;
  discount: number;
  serviceFee?: number;
}

export type Seller = {
  name: string;
  website: string;
  phone?: string;
  email?: string;
};

export type Shopper = {
  email: string;
  displayName: string;
};

export type ShippingAddress = {
  firstName: string;
  lastName: string;
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  phone?: string;
  email?: string;
};

export type UserType = "personal" | "business";
