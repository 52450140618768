import { ReactElement } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AppLogo from "./app-logo";
import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

/**
 * Full Page UI component that displays the info regarding
 * items being out of stock.
 * @returns {ReactElement}
 */
export default function ItemsOutOfStock(props): ReactElement {
  const lineItems: any[] = props?.data?.lineItemsOutOfStock;
  const sellerName = props?.data?.sellerName ?? "";
  const sellerWebsite = props?.data?.sellerWebsite ?? "";

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="sm">
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <AppLogo />
            <Typography gutterBottom variant="h6">
              Some items in this order went out of stock.
            </Typography>

            <ItemsOutOfStockTable lineItems={lineItems} />

            <Divider
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            />
            <Box sx={{ height: "10px" }} />

            <Typography gutterBottom variant="body1">
              This link will become available again once all items are back in
              stock. Alternatively the owner can visit{" "}
              <a target="_blank" href={sellerWebsite}>
                {sellerName}
              </a>{" "}
              to create a new order and payment link.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

/**
 * Ui showing the items out of stock in a table.
 * @param {any} props
 */
function ItemsOutOfStockTable(props: any): ReactElement {
  const rows = props.lineItems;
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <Typography align="left" variant="subtitle1">
                    {row.name}
                  </Typography>
                  <Typography color={"red"} align="left" variant="caption">
                    OUT OF STOCK - Qty {row.quantity}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Typography align="right" variant="subtitle1">
                    {row.total_price_formatted}
                  </Typography>
                  <Typography align="right" variant="caption">
                    {row.unit_price_formatted} each
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
