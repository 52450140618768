import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./checkout-form";

const STRIPE_PUB_KEY =
  "pk_test_51JJ9GxDfegXIg3euTzN9HVfwG0ZEX4RN9MVnSORxf9L4nqe8HtQ33HffqaFcaxT1kcY302uBdutdZwVjAftmEbOp00h8fBzzSL";

//"acct_1JvCJLRVW87cMY5t"
export function PaymentElementForm(props) {
  const order = props.order;
  const stripeAccount = order.merchant_stripe_account;
  const clientSecret = order.stripe_payment_intent_client_secret;

  const stripePromise = loadStripeForConnectAccount(stripeAccount);

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      theme: "stripe",
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm order={order} />
    </Elements>
  );
}

/**
 *
 * @param {string} stripeAccountId - stripe account of the merchant
 * @returns stripePromise to asynchronously load and initialize the Stripe object
 */
export function loadStripeForConnectAccount(stripeAccountId) {
  return loadStripe(STRIPE_PUB_KEY, { stripeAccount: stripeAccountId });
}
