import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

/**
 * Full screen and centered CircularProgressWithLockIcon
 * @returns {JSX.Element}
 */
export default function CircularProgressWithLockIcon(): JSX.Element {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress size="5rem" thickness={2} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LockOutlinedIcon fontSize="large" color="primary" />
        </Box>
      </Box>
    </Box>
  );
}
