import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const secondaryTextColor = "#797979";

/**
 * Returns Ui component showing the purchase details in a table.
 * @param {*} props containing pfm order data.
 */
export default function PurchaseDetails(props) {
  const rows = getRows(props.order);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <TableContainer>
        <Table aria-label="customized table">
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.id}>
                <Grid container spacing={2} marginBottom="10px">
                  <Grid item xs={8}>
                    <Typography margin-left="130px" variant="subtitle1">
                      {row.name}
                    </Typography>
                    <img width={"70px"} src={row.image}></img>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography align="right" variant="subtitle1">
                      {row.totalPriceFormatted}
                    </Typography>
                    <Typography
                      style={{ color: secondaryTextColor }}
                      fontSize="small"
                      align="right"
                      variant="subtitle1"
                    >
                      {row.unitPriceFormatted}
                    </Typography>
                  </Grid>
                </Grid>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  //   backgroundColor: theme.palette.action.hover,
  // },
  "& th, & td": { border: 0 },
}));

/**
 * Get row values to display in the table verbatim.
 * @param {PfmOrder} order
 * @returns {object[]} array of objects
 */
function getRows(order) {
  const source = order.source;
  const lineItems = order.line_items ?? [];
  const orderAmount = order.amount;
  console.log(JSON.stringify(orderAmount));
  const rows = [];

  for (let i = 0; i < lineItems.length; i++) {
    const item = lineItems[i];
    console.log(item.images);
    rows.push({
      name: item.name,
      id: item.id,
      image: item.images,
      unitPriceFormatted: `QTY: ${item.quantity} x ${item.unit_price_formatted}`,
      totalPriceFormatted: item.total_price_formatted,
    });
  }

  const prefix_estimated = source === "browser_extension" ? "Estimated " : "";

  if (orderAmount.shipping) {
    rows.push({
      name: prefix_estimated + "Shipping",
      id: "Shipping____uid",
      quantity: "",
      unitPriceFormatted: "",
      totalPriceFormatted: orderAmount.shipping_formatted,
    });
  }

  if (orderAmount.tax) {
    rows.push({
      name: prefix_estimated + "Tax",
      id: "Tax____uid",
      quantity: "",
      unitPriceFormatted: "",
      totalPriceFormatted: orderAmount.tax_formatted,
    });
  }

  if (orderAmount.serviceFee) {
    rows.push({
      name: "Service Fee",
      id: "ServiceFee____uid",
      quantity: "",
      unitPriceFormatted: "",
      totalPriceFormatted: orderAmount.servicefee_formatted,
    });
  }

  if (orderAmount.total) {
    rows.push({
      name: "Total",
      id: "total____uid",
      quantity: "",
      unitPriceFormatted: "",
      totalPriceFormatted: orderAmount.total_formatted,
    });
  }

  return rows;
}
