import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { TextField } from "@mui/material";
import { isEmail } from "../common/validator";

const CheckoutForm = (props) => {
  const order = props.order;
  const stripeAccount = order.merchant_stripe_account;
  const orderRef = order.id;

  const stripe = useStripe();
  const elements = useElements();

  // Payment error message
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  // Holds the Email TextField value
  const [email, setEmail] = useState(null);
  // Controls validating the Email TextField when the user
  // interacts with it or attempts to submit the form.
  const [validateEmail, setValidatEmail] = useState(false);

  const emailError = validateEmail && !isEmail(email);
  const disablePayButton = !stripe || !elements || paymentProcessing;

  function resetErrorMessage() {
    // Adds whitespace to maintain button position on the layout
    setErrorMessage("\u00A0");
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    resetErrorMessage();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (paymentProcessing) {
      return;
    }

    if (!isEmail(email)) {
      setValidatEmail(true);
      return;
    }

    var returnUrl = new URL(
      `${window.location.protocol}${window.location.host}/payment-return`
    );
    returnUrl.searchParams.append("order_ref", orderRef);
    returnUrl.searchParams.append("stripe_account", stripeAccount);
    // returnUrl.searchParams.append("payer_email", email);

    setPaymentProcessing(true);
    // Since PaymentIntent cannot be edited after a successful payment,
    // this is a good place to update the PaymentIntent with Payer info
    // E.g the receipt email.
    // Watch out for synchonization issues with multiple payers.
    // Otherwise it might be safer to store additional info within pfm.
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl.toString(),
      },
    });
    setPaymentProcessing(false);

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return-url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return-url`.
    }
  };

  return (
    <form>
      <Stack spacing={2}>
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          onBlur={() => setValidatEmail(true)}
          error={emailError}
          helperText={emailError ? "Enter a valid email address" : ""}
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <PaymentElement onChange={(event) => resetErrorMessage()} />
        <LoadingButton
          onClick={handleSubmit}
          loading={paymentProcessing}
          variant="contained"
          disableElevation={true}
          disabled={disablePayButton}
        >
          PAY
        </LoadingButton>
        {/* Show error message to your customers */}
        {errorMessage && <font color="red">{errorMessage}</font>}
      </Stack>
    </form>
  );
};

export default CheckoutForm;
