// Handles payment success redirect from checkout session UI.

import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { retrieveRecieptUrl } from "../stripe/stripe-controller.tsx";

export default function PaymentSuccess() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const sessionId = urlSearchParams.get("session_id");
  const stripeAccount = urlSearchParams.get("stripe_account");

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const { receipt_url } = await retrieveRecieptUrl({
          session_id: sessionId,
          stripe_account: stripeAccount,
        });
        window.location.assign(receipt_url);
      } catch (err) {
        setErrorMessage(`An error occurred: ${err.message}`);
      }
    }

    fetchData();
  }, [errorMessage]);

  if (errorMessage) {
    return (
      <main style={{ padding: "1rem 0" }}>
        <h2>{errorMessage}</h2>
      </main>
    );
  }

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
}
