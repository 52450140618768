import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AppLogo from "../ui-components/app-logo";
import { AppConst } from "../common/app-constants.ts";

export default function PageNotFound() {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack>
        <AppLogo />
        <Typography variant="h3">Sorry, we couldn't find this page</Typography>
        <Typography>
          `{"The link you followed may be broken or the page isn't available. "}
          {
            <a
              href="https://payforme.io/business"
              target="_self"
              rel="noopener noreferrer"
            >
              Go to {AppConst.APP_NAME}.
            </a>
          }
          `
        </Typography>
      </Stack>
    </Box>
  );
}
