/// This file handles authentication related logic.
import { onAuthStateChanged, User } from "firebase/auth";
// @ts-ignore
import { getFbAuth } from "../firebase/firebase-initializer.ts";

/**
 * @returns the currently signed in user or null if not signed in.
 */
export async function getCurrentUser(): Promise<User | null> {
  return await new Promise((resolve, reject) =>
    onAuthStateChanged(
      getFbAuth(),
      (user) => {
        if (user) {
          console.log("User is signed in");
        } else {
          console.log("User is signed out");
        }
        resolve(user);
      },
      (error) => reject(error)
    )
  );
}

/**
 * Signs out the current user.
 */
export async function signOutCurrentUser() {
  await getFbAuth().signOut();
}
