import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import Chart from './Chart';
import Deposits from "../ui-components/Deposits.tsx";
import Orders from "../ui-components/Orders.tsx";
import "../App.css";

import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import {
  canFetchDashboardInfo,
  fetchDashboardInfo,
  // @ts-ignore
} from "../dashboard/dashboard-controller.ts";
// @ts-ignore
import { signOutCurrentUser } from "../auth/auth-controller.ts";
import AppLogo from "../ui-components/app-logo.jsx";

const mdTheme = createTheme();

export default function DashboardTwo() {
  return <DashboardContent />;
}

function CopyButtonWithAlert(props) {
  const link = props.link;
  const buttonText = props.text;

  const [open, setOpen] = useState(false);

  async function copyToClipBoard(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy to clipboard: ", err);
    }
  }

  const handleClick = () => {
    copyToClipBoard(link);
    setOpen(true);
    // logAppEvent("select_content", {
    //   content_type: "button",
    //   item_id: "copy_link_button",
    //   item_name: "copy_link_button",
    // });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Button
        style={{
          backgroundColor: "#FFF",
          color: "#18A0FB",
          border: "1px solid #18A0FB",
          padding: "20px",
          paddingTop: "0px",
          paddingBottom: "0px",
          height: "40px",
        }}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={14000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          <Stack>
            <Typography>Item copied!</Typography>
          </Stack>
        </Alert>
      </Snackbar>
    </div>
  );
}

function StripeRedirect() {
  window.location.href = "https://connect.stripe.com/balance/overview";
}

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [selected, setSelected] = React.useState("orders");
  const ordersSelected = () => {
    setSelected("orders");
  };

  const settingsSelected = () => {
    setSelected("settings");
  };

  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const canFetch = await canFetchDashboardInfo();
      if (!canFetch) {
        console.log("Cannot fetch dashboard info");
        window.location.assign("/signed-in");
        return;
      }

      const data = await fetchDashboardInfo();
      setDashboardData(data);
    }

    fetchData();
  }, []);

  function onClickSignOut() {
    signOutCurrentUser();
    window.location.assign("/signin");
  }

  if (!dashboardData) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  const storeName = dashboardData.displayName;
  const email = dashboardData.email;
  const merchantId = dashboardData.merchantId;
  const stripeAccount = dashboardData.stripeAccountId;
  const apiKey = dashboardData.apiKey;

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        {/* <CssBaseline /> */}
        {/* <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar> */}

        {/* <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer> */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <AppLogo />
            </Box>
            <Container
              style={{ backgroundColor: "white", paddingBottom: "30px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginLeft: "10px",
                  padding: "5px",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  fontWeight={600}
                  style={{
                    marginTop: "20px",
                    fontSize: "20px",
                  }}
                  className={`isSelected ${
                    selected === "orders" ? "" : "isNotSelected"
                  }`}
                  onClick={ordersSelected}
                >
                  {" "}
                  Orders
                </Typography>
                <Typography
                  fontWeight={600}
                  style={{
                    marginLeft: "15px",
                    marginRight: "15px",
                    marginTop: "20px",
                    fontSize: "20px",
                  }}
                  className={`isSelected ${
                    selected === "settings" ? "" : "isNotSelected"
                  }`}
                  onClick={settingsSelected}
                >
                  {" "}
                  Settings
                </Typography>
              </Box>
              {selected === "orders" && (
                <Box>
                  <Grid container spacing={3}>
                    {/* Chart */}
                    <Grid item xs={12} md={8} lg={9}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: 240,
                        }}
                      >
                        {/* <Chart /> */}
                      </Paper>
                    </Grid>

                    {/* Recent Deposits */}
                    <Grid item xs={12} md={4} lg={3}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: 240,
                        }}
                      >
                        <Deposits />
                      </Paper>
                    </Grid>
                    {/* Recent Orders */}
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Orders />
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {selected === "settings" && (
                <Box>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      marginBottom: "50px",
                      display: "flex",
                      padding: "40px",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      fontWeight={300}
                      style={{
                        fontSize: "35px",
                      }}
                    >
                      Here’s some account info that you may find helpful
                    </Typography>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1.5fr 2fr 1fr",
                        gridColumnGap: "30px",
                        width: "60%",
                        alignItems: "center",
                      }}
                    >
                      <h4 style={{ textAlign: "left" }}> MERCHANT ID</h4>
                      <h4
                        style={{
                          fontWeight: "400",
                          textAlign: "left",
                        }}
                      >
                        {merchantId}
                      </h4>

                      <CopyButtonWithAlert link={merchantId} text="Copy" />

                      <h4 style={{ textAlign: "left" }}> STRIPE ACCOUNT </h4>
                      <h4
                        style={{
                          fontWeight: "400",
                          textAlign: "left",
                        }}
                      >
                        {stripeAccount}
                      </h4>
                      <CopyButtonWithAlert link={stripeAccount} text="Copy" />

                      <h4 style={{ textAlign: "left" }}> API KEY </h4>
                      <h4
                        style={{
                          fontWeight: "400",
                          textAlign: "left",
                        }}
                      >
                        {apiKey}
                      </h4>
                      <CopyButtonWithAlert link={apiKey} text="Copy" />
                    </Box>
                  </Box>
                </Box>
              )}
            </Container>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
