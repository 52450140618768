import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import stripeLogo from "../stripeLogo.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Container } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  canFetchDashboardInfo,
  fetchDashboardInfo,
} from "../dashboard/dashboard-controller.ts";
import { signOutCurrentUser } from "../auth/auth-controller.ts";
import { AppConst, AppRoute } from "../common/app-constants.ts";
import AppLogoPlusStripeLogo from "../ui-components/AppLogoPlusStripeLogo.tsx";

export default function DashboardPage() {
  return <DashboardUi />;
}

function CopyButtonWithAlert(props) {
  const link = props.link;
  const buttonText = props.text;

  const [open, setOpen] = useState(false);

  async function copyToClipBoard(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy to clipboard: ", err);
    }
  }

  const handleClick = () => {
    copyToClipBoard(link);
    setOpen(true);
    // logAppEvent("select_content", {
    //   content_type: "button",
    //   item_id: "copy_link_button",
    //   item_name: "copy_link_button",
    // });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Button
        style={{
          backgroundColor: "#FFF",
          color: "#18A0FB",
          border: "1px solid #18A0FB",
          padding: "20px",
          paddingTop: "0px",
          paddingBottom: "0px",
          height: "40px",
        }}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={14000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          <Stack>
            <Typography>Item copied!</Typography>
          </Stack>
        </Alert>
      </Snackbar>
    </div>
  );
}

function StripeRedirect() {
  window.location.href = "https://connect.stripe.com/balance/overview";
}

function DashboardUi(props) {
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const canFetch = await canFetchDashboardInfo();
      if (!canFetch) {
        console.log("Cannot fetch dashboard info");
        window.location.assign(AppRoute.SIGNED_IN);
        return;
      }

      const data = await fetchDashboardInfo();
      setDashboardData(data);
    }

    fetchData();
  }, []);

  function onClickSignOut() {
    signOutCurrentUser();
    window.location.assign(AppRoute.SIGNIN);
  }

  if (!dashboardData) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  const storeName = dashboardData.displayName;
  const email = dashboardData.email;
  const merchantId = dashboardData.merchantId;
  const stripeAccount = dashboardData.stripeAccountId;
  const apiKey = dashboardData.apiKey;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor="#F2F2F2"
    >
      <Container maxWidth="lg">
        <h1 style={{ fontWeight: 400, paddingLeft: "10px", marginTop: "50px" }}>
          Welcome, {storeName}
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4 style={{ fontWeight: 400, margin: "0px", paddingLeft: "10px" }}>
            {email}
          </h4>
          <Button
            style={{ backgroundColor: "#18A0FB", color: "#fff" }}
            onClick={onClickSignOut}
          >
            Sign Out
          </Button>
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            marginTop: "30px",
            display: "flex",
            padding: "40px",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            borderRadius: "10px",
          }}
        >
          <AppLogoPlusStripeLogo />
          <h4 style={{ fontWeight: "400" }}>
            {AppConst.APP_NAME} partners with Stripe for financial services. For
            Payouts, Balance and Transactions
          </h4>

          <Button
            style={{
              backgroundColor: "#FFF",
              color: "#18A0FB",
              border: "1px solid #18A0FB",
              padding: "20px",
            }}
            onClick={StripeRedirect}
          >
            GO TO STRIPE DASHBOARD
          </Button>
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            marginTop: "30px",
            marginBottom: "50px",
            display: "flex",
            padding: "40px",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            borderRadius: "10px",
          }}
        >
          <h2
            style={{
              fontWeight: "300",
              fontSize: "35px",
            }}
          >
            Here’s some account info that you may find helpful
          </h2>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.5fr 2fr 1fr",
              gridColumnGap: "30px",
              width: "60%",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "left" }}> MERCHANT ID</h4>
            <h4
              style={{
                fontWeight: "400",
                textAlign: "left",
              }}
            >
              {merchantId}
            </h4>

            <CopyButtonWithAlert link={merchantId} text="Copy" />

            <h4 style={{ textAlign: "left" }}> STRIPE ACCOUNT </h4>
            <h4
              style={{
                fontWeight: "400",
                textAlign: "left",
              }}
            >
              {stripeAccount}
            </h4>
            <CopyButtonWithAlert link={stripeAccount} text="Copy" />

            <h4 style={{ textAlign: "left" }}> API KEY </h4>
            <h4
              style={{
                fontWeight: "400",
                textAlign: "left",
              }}
            >
              {apiKey}
            </h4>
            <CopyButtonWithAlert link={apiKey} text="Copy" />
          </div>
        </div>
      </Container>
    </Box>
  );
}
