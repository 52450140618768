import "./App.css";
// @ts-ignore
import { AppConst } from "./common/app-constants.ts";
import AppLogo from "./ui-components/app-logo";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <AppLogo />
        <p>Businesses sell more with {AppConst.APP_NAME}.</p>
        <a
          className="App-link"
          href="https://payforme.io/business"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </header>
    </div>
  );
}

export default App;
