// Handles fetching stripe specific data & logic.
// @ts-ignore
import { getFbFunctions } from "../firebase/firebase-initializer.ts";
import { httpsCallable } from "firebase/functions";
import {
  CreateCheckoutSessionParamForDirectPay,
  CreateCheckoutSessionParamForPfmOrder,
  CreateSessionResponse,
  RetrieveReceiptParam,
} from "../models/app-models";
// @ts-ignore
import { AppConst } from "../common/app-constants.ts";

/**
 * Returns the paymentIntent payload for the `paymentIntentId`
 * @param {any} body
 */
export async function fetchPaymentIntent(body: any) {
  body.function = "retrievePaymentIntent";
  const functions = getFbFunctions();
  const callable = httpsCallable(functions, AppConst.CALLABLE_API_V1_ENDPOINT);
  const result = await callable(body);
  return result.data;
}

/**
 * Creates Stripe checkout session and returns the session url and other
 * useful data such as the order status, items out of stock, etc.
 * @param {
 * CreateCheckoutSessionParamForDirectPay |
 * CreateCheckoutSessionParamForPfmOrder
 * } body the POST body
 * @returns {Promise<CreateSessionResponse>}
 */
export async function createCheckoutSession(
  data:
    | CreateCheckoutSessionParamForDirectPay
    | CreateCheckoutSessionParamForPfmOrder
): Promise<CreateSessionResponse> {
  const body = data as any;
  body.function = "createCheckoutSession";
  const functions = getFbFunctions();
  const callable = httpsCallable(functions, AppConst.CALLABLE_API_V1_ENDPOINT);
  const result = await callable(body);
  return result.data as CreateSessionResponse;
}

/**
 * Fetches the receiptUrl for the session
 * @param {RetrieveReceiptParam} param
 * @returns {any} receiptUrl payload
 */
export async function retrieveRecieptUrl(param: RetrieveReceiptParam) {
  const body = param as any;
  body.function = "retrieveReceiptUrl";
  const functions = getFbFunctions();
  const callable = httpsCallable(functions, AppConst.CALLABLE_API_V1_ENDPOINT);
  const result = await callable(body);
  return result.data;
}

/**
 * Checks if the authenticated user is fully onboarded with Stripe.
 * Firebase functions sdk handles user auth automatically.
 * Therefore we don't need to pass a userId or auth token.
 * @returns {Promise<boolean>} true if fully onboarded with Stripe.
 */
export async function checkIsFullyOnboardedWithStripe(): Promise<boolean> {
  const functions = getFbFunctions();
  const callable = httpsCallable(functions, AppConst.CALLABLE_API_V1_ENDPOINT);
  const result = await callable({
    function: "checkIsFullyOnboardedWithStripe",
  });
  const data = result.data as any;
  return data["isFullyOnboarded"] === true;
}

/**
 * Gets an existing account links url if the authenticated user
 * has previously started the process or creates a new one.
 * This is required for onboarding the current user with Stripe.
 * Firebase functions sdk handles user auth automatically.
 * Therefore we don't need to pass a userId or auth token.
 * @returns {Promise<any>} payload with the account links.
 */
export async function createStripeAccountLinks(): Promise<any> {
  const functions = getFbFunctions();
  const callable = httpsCallable(functions, AppConst.CALLABLE_API_V1_ENDPOINT);
  const result = await callable({ function: "createStripeAccountLinks" });
  return result.data;
}
