/**
 * Provides access to app configs from environment variables.
 */
export class AppConfig {
  static readonly IS_PROD_ENVIRONMENT = process.env.REACT_APP_ENV === "prod";

  static getFirebaseConfig() {
    const devFirebaseConfig = {
      apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DEV_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID,
    };

    const prodFirebaseConfig = {
      apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_PROD_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID,
    };

    return this.IS_PROD_ENVIRONMENT ? prodFirebaseConfig : devFirebaseConfig;
  }
}
