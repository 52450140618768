import { useState, useEffect } from "react";
import * as React from "react";
import {
  Typography,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  Grid,
  Divider,
  TextField,
} from "@mui/material";
import Container from "@mui/material/Container";
import { Box } from "@mui/system";
// @ts-ignore
import { fetchCheckoutLink } from "../orders/order-controller.tsx";
// @ts-ignore
import TermsAndConditionsFooter from "../ui-components/TermsAndConditionsFooter.tsx";
// @ts-ignore
import CircularProgressWithLockIcon from "../ui-components/CircularProgressWithLockIcon.tsx";
import {
  CheckoutLinkParamForBrowserExtension,
  CheckoutLinkResponse,
  ExtensionData,
  ExtensionDataCartItem,
  ExtensionDataProductVariation,
  ExtensionDataShippingAddress,
} from "../models/app-models";
import { Elements } from "@stripe/react-stripe-js";
import { AddressElement } from "@stripe/react-stripe-js";
import { loadStripeForConnectAccount } from "../stripe/payment-element-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmail } from "../common/validator";
import {
  StripeAddressElementChangeEvent,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import currency from "currency.js";
import AppLogo from "../ui-components/app-logo.jsx";

const stripePromise = loadStripeForConnectAccount("");

/**
 * Component for adding items from chrome extension or other external sources.
 * Expects url parameter containing JSON data payload.
 */
export default function ExtensionPage() {
  var href = window.location.href;

  const initialUiData = {
    ready: false,
    data: {} as ExtensionData,
  };

  const [uiData, setUiData] = useState(initialUiData);

  async function fetchData() {
    try {
      const dataString = new URL(href).searchParams.get("data");
      if (dataString) {
        const data = JSON.parse(dataString);
        setUiData({
          ready: true,
          data: data,
        });
      }
    } catch (err) {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (!uiData.ready) {
    return <CircularProgressWithLockIcon />;
  }

  const data = uiData.data;
  const rows = data.cartItems;

  return (
    <Container maxWidth="md">
      <Box
        sx={{ m: 2 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Stack>
          <AppLogo />
          <Box sx={{ height: "10px" }} />
          <Typography variant="body2">
            Easiest way to let your friends, family and fans on social media pay
            for you
          </Typography>
          <Box sx={{ height: "50px" }} />

          <Typography variant="h4">
            {data.totalItemCount == 1
              ? "Here is the item you want to purchase"
              : "Here are the items you want to purchase"}
          </Typography>

          <Box sx={{ height: "20px" }} />

          <TableContainer>
            <Table aria-label="customized table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.productId}>
                    <Stack>
                      <Box sx={{ height: "20px" }} />

                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={3}>
                          <Grid item xs="auto">
                            <img
                              width={"140px"}
                              src={row.images?.length ? row.images[0] : ""}
                            ></img>
                          </Grid>
                          <Grid item xs={true}>
                            <Stack>
                              <Typography>{row.name}</Typography>
                              <Box sx={{ height: "10px" }} />
                              {productVariationsToHtml(row.productVariation)}
                              <Box sx={{ height: "10px" }} />
                              <Typography variant="body2">
                                <a
                                  style={{
                                    color: "darkblue",
                                  }}
                                  target="_blank"
                                  href={row.productLink}
                                >
                                  Original listing
                                </a>
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={2}>
                            <Stack>
                              <Typography align="right">
                                {formattedCurrency(row.price, row.currency)}
                              </Typography>
                              <Typography align="right">
                                Qty {row.quantity}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ height: "50px" }} />

          {subTotalContainer(data.cartItems)}

          <Box sx={{ height: "30px" }} />
          <Divider />
          <Box sx={{ height: "30px" }} />

          <ShippingInfoContainer data={data} />

          <Box sx={{ height: "30px" }} />
          <TermsAndConditionsFooter />
        </Stack>
      </Box>
    </Container>
  );
}

function productVariationsToHtml(
  productVariations: ExtensionDataProductVariation[]
) {
  let htmlString = "";
  for (const item of productVariations) {
    htmlString += `<strong>${item.name}</strong> ${item.value} </br>`;
  }

  return <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>;
}

function subTotalContainer(cartItems: ExtensionDataCartItem[]) {
  const subTotal: currency = cartItems.reduce(
    (prev, item) => prev.add(item.subtotal),
    currency(0)
  );

  const subtotalFormatted = formattedCurrency(
    subTotal.toString(),
    cartItems[0].currency
  );

  return (
    <Stack direction="row" justifyContent="end">
      <Stack maxWidth="sm">
        <Grid container>
          <Grid item xs={true}>
            <Stack>
              <Typography>Subtotal</Typography>
            </Stack>
          </Grid>
          <Grid item xs={true}>
            <Typography align="right">{subtotalFormatted}</Typography>
          </Grid>
        </Grid>

        <Box sx={{ height: "15px" }} />
        <Divider />
        <Box sx={{ height: "15px" }} />

        <Grid container>
          <Grid item xs={true}>
            <Stack>
              <Typography sx={{ fontWeight: "bold" }}>
                Estimated Total including Taxes and Fees
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={true}>
            <Typography sx={{ fontWeight: "bold" }} align="right">
              Calculated at checkout
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}

function ShippingInfoContainer(props: any) {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Stack>
        <Typography variant="h4">
          Tell us where to deliver your order
        </Typography>
        <Box sx={{ height: "5px" }} />
        <Typography variant="caption">
          Your delivery information is private, only the seller can see it
        </Typography>
        <Box sx={{ height: "20px" }} />

        <AddressElementForm data={props.data} />

        <Box sx={{ height: "20px" }} />
      </Stack>
    </Grid>
  );
}

function AddressElementForm(props: any) {
  const data: ExtensionData = props.data;

  const [elementChangeEvent, setElementChangeEvent] =
    useState<StripeAddressElementChangeEvent>();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const disableButton = !isEmail(email) || !elementChangeEvent?.complete;

  const handleSubmit = async (event) => {
    // don't refresh the page.
    event.preventDefault();

    const elementValue = elementChangeEvent!.value;
    const address = elementValue.address;
    const nameSplit = elementValue.name.split(" ");
    const firstName = nameSplit[0].trim();
    const lastName = nameSplit.length > 1 ? nameSplit[1].trim() : "";

    const fullAddress: ExtensionDataShippingAddress = {
      firstName: firstName,
      lastName: lastName,
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      state: address.state,
      postalCode: address.postal_code,
      countryCode: address.country,
      email: email,
    };

    const param: CheckoutLinkParamForBrowserExtension = {
      function: "checkoutLink",
      website: data.website,
      source: "browser_extension",
      totalItemCount: data.totalItemCount,
      needsShipping: true,
      shippingAddress: fullAddress,
      cartItems: data.cartItems,
    };

    try {
      setIsLoading(true);
      console.log("submitting" + JSON.stringify(param));
      const res: CheckoutLinkResponse = await fetchCheckoutLink(param);
      window.location.assign(res.data.checkoutLink);
    } catch (error) {
      console.log("error: " + JSON.stringify(error));
      setIsLoading(false);
    }
  };

  const options: StripeElementsOptions = {
    appearance: {
      theme: "stripe",
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <form>
        <Stack>
          <TextField
            required
            fullWidth
            id="email"
            label="Your Email"
            name="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Box sx={{ height: "10px" }} />
          <AddressElement
            options={{ mode: "shipping" }}
            onChange={(event) => setElementChangeEvent(event)}
          />
          <Box sx={{ height: "10px" }} />
          <LoadingButton
            onClick={handleSubmit}
            loading={isLoading}
            variant="contained"
            disableElevation={true}
            disabled={disableButton}
          >
            SUBMIT
          </LoadingButton>
        </Stack>
      </form>
    </Elements>
  );
}

/**
 * Formats currency string
 * @param {string} amount e.g. "1.23"
 * @param {string}  currencyAbr e.g. USD
 * @returns {string} formatted currency string e.g. "1.23 USD"
 */
function formattedCurrency(amount: string, currencyAbr: string): string {
  const result =
    currency(amount).format({
      separator: ",",
      symbol: "",
    }) +
    " " +
    currencyAbr;

  return result.trim();
}
