// Handles fetching user specific data & logic.

import { httpsCallable } from "firebase/functions";
// @ts-ignore
import { getFbFunctions } from "../firebase/firebase-initializer.ts";
// @ts-ignore
import { AppConst } from "../common/app-constants.ts";
import { UserType } from "../models/app-models";

/**
 * Creates a new user doc in the db using the authenticated User
 * only if the new user doc does not already exist.
 * @param {UserType} userType e.g. "personal" or "business"
 * @returns {Promise<any>} ignored result. 200 OK is sufficient.
 */
export async function createUserIfNotExists(userType: UserType): Promise<any> {
  const functions = getFbFunctions();
  const callable = httpsCallable(functions, AppConst.CALLABLE_API_V1_ENDPOINT);
  const result = await callable({
    function: "createUserIfNotExists",
    userType: userType,
  });
  return result.data;
}
