import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import AppLogo from "../ui-components/app-logo";
// @ts-ignore
import { getFbAuth, getFbUiConfig } from "../firebase/firebase-initializer.ts";
// @ts-ignore
import { AppRoute } from "../common/app-constants.ts";

export default function BusinessSignIn() {
  const href = window.location.href;
  let destination = new URL(href).searchParams.get("destination");
  if (!destination) {
    // this is the default destination
    destination = AppRoute.SIGNED_IN;
  }

  return (
    <Box
      sx={{ margin: 2 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="sm">
        <Stack spacing={1}>
          <AppLogo />
          <Typography variant="h4">
            Sign-In or Create a Business Account
          </Typography>
          <Typography variant="subtitle1">
            For businesses, sellers and merchants
          </Typography>
          <Box sx={{ height: "10px" }} />
          <Divider />
          <StyledFirebaseAuth
            uiConfig={getFbUiConfig(destination)}
            firebaseAuth={getFbAuth()}
          />
        </Stack>
      </Container>
    </Box>
  );
}
