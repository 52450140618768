import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Stack,
  Divider,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import Container from "@mui/material/Container";
import { Box } from "@mui/system";
// @ts-ignore
import TermsAndConditionsFooter from "../ui-components/TermsAndConditionsFooter.tsx";
// @ts-ignore
import { useForm, SubmitHandler } from "react-hook-form";
import AppLogo from "../ui-components/app-logo";
import { isValidHttpUrl } from "../common/validator";
import {
  ExtensionData,
  ExtensionDataCartItem,
  ExtensionDataProductVariation,
} from "../models/app-models";
import currency from "currency.js";

type FormInputs = {
  productName: string;
  productLink: string;
  productVariation: string;
  imageUrl: string;
  price: string;
  currency: string;
  quantity: string;
};

/**
 * Component for adding items from chrome extension or other external sources.
 * Expects url parameter containing JSON data payload.
 */
export default function AddProductPage() {
  return (
    <Container maxWidth="sm">
      <Stack>
        <Grid container justifyContent="center" alignItems="center">
          <AppLogo />
        </Grid>
        <Box sx={{ height: "10px" }} />
        <Typography variant="h3">
          Shop from anywhere, let someone else pay!
        </Typography>
        <Typography marginTop={2} variant="h6">
          Add the products you want from your preferred online store!
        </Typography>
        <Box sx={{ height: "30px" }} />
        <Divider />
        <Box sx={{ height: "30px" }} />

        <FormContainer />

        <Box sx={{ height: "30px" }} />
        <Divider />
        <Box sx={{ height: "30px" }} />
        <TermsAndConditionsFooter />
      </Stack>
    </Container>
  );
}

function FormContainer() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormInputs>();
  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    const variationStr = data.productVariation.trim();
    const productVariation: ExtensionDataProductVariation[] =
      variationStr.length ? [{ name: "Variation", value: variationStr }] : [];
    const quantity = Number(data.quantity);
    const price = data.price;
    const subtotal = currency(price).multiply(quantity).format({ symbol: "" });
    const productLink = data.productLink;
    const website = new URL(productLink).hostname;

    const cartItems: ExtensionDataCartItem[] = [
      {
        name: data.productName,
        productId: productLink,
        productLink: productLink,
        productVariation: productVariation,
        currency: data.currency.toUpperCase(),
        price: price,
        subtotal: subtotal,
        quantity: data.quantity,
        images: [data.imageUrl],
      },
    ];
    const extensionData: ExtensionData = {
      website: website,
      totalItemCount: quantity,
      cartItems: cartItems,
    };
    const dataString = JSON.stringify(extensionData);
    const encoded = encodeURIComponent(dataString);
    console.log(dataString);
    navigate(`/extension?data=${encoded}`);
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <div>
          <TextField
            fullWidth
            label="Product name or description"
            multiline
            maxRows={4}
            {...register("productName", { required: true })}
          />
          {errors.productName && (
            <Typography variant="body2" color={"red"}>
              This field is required
            </Typography>
          )}
        </div>

        <TextField
          label="Product variation e.g. size, color, type etc."
          multiline
          maxRows={4}
          {...register("productVariation")}
        />

        <div>
          <TextField
            fullWidth
            label="Product link e.g. https://www.amazon.com/myproduct"
            {...register("productLink", {
              required: true,
              validate: (value) => isValidHttpUrl(value),
            })}
          />
          {errors.productLink && (
            <Typography variant="body2" color={"red"}>
              This field requires a valid URL that starts with https://
            </Typography>
          )}
        </div>

        <div>
          <TextField
            fullWidth
            label="Product Image URL e.g. https://amazon.com/pic.jpg"
            {...register("imageUrl", {
              required: true,
              validate: (value) => isValidHttpUrl(value),
            })}
          />
          {errors.imageUrl && (
            <Typography variant="body2" color={"red"}>
              This field requires a valid URL that starts with https://
            </Typography>
          )}
        </div>

        <Grid container spacing={0}>
          <Grid item xs={true}>
            <Stack>
              <TextField
                fullWidth
                label="Price e.g. 97.50"
                {...register("price", {
                  required: true,
                  pattern: /^\d+(?:\.\d+)?$/,
                })}
              />
              {errors.price && (
                <Typography variant="body2" color={"red"}>
                  This field requires a valid price e.g. 97.50
                </Typography>
              )}
            </Stack>
          </Grid>
          <Box sx={{ width: "10px" }} />

          <Grid item xs={true}>
            <Stack>
              <TextField
                fullWidth
                label="Currency e.g. USD"
                defaultValue={"USD"}
                {...register("currency", {
                  required: true,
                  pattern: /^[a-zA-Z]{3}$/i,
                })}
              />
              {errors.currency && (
                <Typography variant="body2" color={"red"}>
                  This field requires a valid three-letter ISO currency code
                  e.g. USD
                </Typography>
              )}
            </Stack>
          </Grid>
          <Box sx={{ width: "10px" }} />
          <Grid item xs={true}>
            <Stack>
              <TextField
                fullWidth
                defaultValue={1}
                label="Quantity e.g. 1"
                {...register("quantity", {
                  required: true,
                  pattern: /^[1-9]\d*$/,
                })}
              />
              {errors.quantity && (
                <Typography variant="body2" color={"red"}>
                  This field requires a valid number
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>

        <Button variant="contained" type="submit">
          Continue
        </Button>
      </Stack>
    </form>
  );
}
