import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageNotFound from "./routes/page-not-found";
import PaymentLinkPage from "./routes/payment-link.tsx";
import CheckoutPage from "./routes/checkout-page";
import CheckoutPageOld from "./routes/checkout-page-old";
import PaymentSuccess from "./routes/payment-success";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue, pink } from "@mui/material/colors";
import DashboardPage from "./routes/dashboard";
import SignIn from "./routes/signin.tsx";
import SignedInPage from "./routes/signed-in.tsx";
import VerifyEmailPage from "./routes/verify-email";
import DashboardTwo from "./routes/dashboard-two.tsx";
import DirectPaymentPage from "./routes/direct-payment.tsx";
import PaymentLinkPageCustom from "./routes/payment-link-custom.tsx";
import ExtensionPage from "./routes/extension-page.tsx";
import PaymentReturn from "./routes/payment-return";
import AddProductPage from "./routes/add-product.tsx";
import StoreSignInPage from "./routes/store-signin.tsx";
import StoreCheckoutPage from "./routes/store-checkout.tsx";
import DisplayPaymentLinkPage from "./routes/display-payment-link.tsx";
import ConnectWooStorePage from "./routes/connect-woo-store.tsx";
import ConnectWooStoreMessagePage from "./routes/connect-woo-store-msg.tsx";
import OptionalDebugBanner from "./ui-components/debug-banner.tsx";

const appTheme = createTheme({
  palette: {
    primary: { main: blue[700] },
    secondary: { main: pink[500] },
  },
});

ReactDOM.render(
  <ThemeProvider theme={appTheme}>
    <BrowserRouter>
      {<OptionalDebugBanner />}
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="pfm/:orderRef" element={<PaymentLinkPage />} />
        <Route path="payment-success" element={<PaymentSuccess />} />
        <Route path="payment-return" element={<PaymentReturn />} />
        <Route path="checkout-page" element={<CheckoutPage />} />
        <Route path="checkout-page-old" element={<CheckoutPageOld />} />
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="signed-in" element={<SignedInPage />} />
        <Route path="verify-email" element={<VerifyEmailPage />} />
        <Route path="biz/:merchantId" element={<DirectPaymentPage />} />
        <Route path="dashboard-two" element={<DashboardTwo />} />
        <Route path="pfm2/:orderRef" element={<PaymentLinkPageCustom />} />
        <Route path="extension" element={<ExtensionPage />} />
        <Route path="add-product" element={<AddProductPage />} />
        <Route path="store-signin" element={<StoreSignInPage />} />
        <Route path="store-checkout" element={<StoreCheckoutPage />} />
        <Route
          path="connect-woo-store-msg"
          element={<ConnectWooStoreMessagePage />}
        />
        <Route path="connect-woo-store" element={<ConnectWooStorePage />} />
        <Route
          path="display-payment-link"
          element={<DisplayPaymentLinkPage />}
        />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
