/// Handles Email Verification.

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Stack, Typography, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { getCurrentUser } from "../auth/auth-controller.ts";
import AppLogo from "../ui-components/app-logo";
import { logAppEvent } from "../firebase/firebase-initializer.ts";
import EmailIcon from "@mui/icons-material/Email";
import { sendEmailVerification } from "firebase/auth";

export default function VerifyEmailPage() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    async function doAsync() {
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        window.location.assign("/signin");
        return;
      }

      if (currentUser.emailVerified) {
        window.location.assign("/signed-in");
        return;
      }

      await sendEmailVerification(currentUser);
      setCurrentUser(currentUser);
    }

    doAsync();
  }, []);

  if (!currentUser) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  // sends email verification
  const onClickSendEmailVerification = () => {
    sendEmailVerification(currentUser);
    logAppEvent("select_content", {
      content_type: "button",
      item_id: "send_email_verification_button",
      item_name: "send_email_verification_button",
    });
  };

  const onClickEmailActivated = () => {
    window.location.assign("/signed-in");
    logAppEvent("select_content", {
      content_type: "button",
      item_id: "email_activated_button",
      item_name: "email_activated_button",
    });
  };

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack spacing={1} alignItems="center">
        <AppLogo />
        <Typography variant="h4" align="center">
          Verify Your Email Address
        </Typography>
        <Typography variant="subtitle1" align="center">
          Check your email and click the link to activate your account
        </Typography>
        <EmailIcon sx={{ fontSize: 120 }} color="secondary" />
        <Button sx={{ m: 1 }} onClick={onClickSendEmailVerification}>
          Re-send verification email
        </Button>
        <Typography variant="subtitle1" align="center" color="green">
          Email sent to {currentUser.email}
        </Typography>
        <Button
          sx={{ m: 1 }}
          variant="contained"
          onClick={onClickEmailActivated}
        >
          Email is activated. Let's Continue
        </Button>
      </Stack>
    </Box>
  );
}
